.card-avantages {
    display: flex;
    align-items: center;
    position: relative;

    @media #{$max-sm} {
        max-width: rem(267px);
        margin: 0 auto;
    }

    &-wrapper {

        &:first-child {
            @media #{$max-sm} {
                padding-top: rem(38px);
            }
        }

        @media #{$max-sm} {
            padding-top: rem(88px);
        }

        &:nth-child(2) {
            .card-avantages {
                &-icright {
                    right: rem(-44px);

                    @media #{$max-sm} {
                        right: 50%;
                    }
                }
            }
        }

        &:nth-child(3) {

            padding-left: rem(54px);

            .card-avantages {

                &-img {
                    margin-right: rem(-27px);
                }

                &-body {
                    padding-right: 0;
                }
            }
        }
    }

    &-icright {
        position: absolute;
        font-size: rem(24px);
        right: rem(0px);
        top: 50%;
        background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGhlaWdodD0iNTEycHgiIGlkPSJMYXllcl8xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MTIgNTEyOyIgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgd2lkdGg9IjUxMnB4IiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48cG9seWdvbiBwb2ludHM9IjE2MCwxMTUuNCAxODAuNyw5NiAzNTIsMjU2IDE4MC43LDQxNiAxNjAsMzk2LjcgMzEwLjUsMjU2ICIvPjwvc3ZnPg==);
        display: inline-block;
        height: rem(45px);
        width: rem(45px);
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
        transform: translateY(-50%);

        @media #{$max-md} {
            height: rem(30px);
            width: rem(30px);
        }


        @media #{$max-sm} {
            right: 50%;
            bottom: 0;
            transform: translateX(50%) translateY(66px) rotate(90deg);
        }

    }

    &-img {
        flex: 0 0 rem(80px);
        height: rem(80px);
        position: relative;
        margin-right: rem(-16px);

        @media #{$max-md} {
            flex: 0 0 rem(60px);
            height: rem(60px);
        }


        @media #{$max-sm} {
            flex: 0 0 rem(80px);
            height: rem(80px);
        }

        &::after {
            content: "";
            display: inline-block;
            height: 100%;
            width: 100%;
            background-image: linear-gradient(to right, transparent, $white);
            position: absolute;
            right: 0;
            top: 0;

        }

        img {
            width: 100%;
            height: 100%;
        }
    }

    &-title {
        font-size: rem(24px);
        font-weight: bold;
        line-height: rem(29px);
        margin-bottom: rem(4px);
        color: $primary;

        @media #{$max-md} {
            font-size: rem(16px);
            font-weight: bold;
            line-height: rem(18px);
        }

        @media #{$max-sm} {
            font-size: rem(18px);
            line-height: rem(20px);
            padding-top: rem(11px);
        }
    }

    &-body {
        position: relative;
        z-index: 2;
        padding-right: rem(30px);


        @media #{$max-sm} {
            padding-right: rem(0px);
        }

        p {
            font-size: rem(16px);
            color: $gray-8a8;
            line-height: rem(19px);
            max-width: rem(207px);
            font-weight: 500;


            @media #{$max-md} {
                font-size: rem(12px);
                line-height: rem(15px);
            }


            @media #{$max-sm} {
                font-size: rem(16px);
                line-height: rem(18px);
            }
        }
    }

    &-container {
        max-width: rem(1572px);

    }
}