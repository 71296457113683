.jodropdown {
    position: relative;
    cursor: pointer;
    display: inline-block;

    &-list {
        position: absolute;
        top: 100%;
        left: 0;
        background-color: $white;
        list-style: none;
        padding: 0;
        border-radius: rem(0px);
        box-shadow: 0px 1px 3px 1px rgba($black, 0.2);
        transform-origin: top;
        transform: scaleY(0);
        transition-duration: .2s;
        padding: 0;
        z-index: 4;
        margin-bottom: 0;

        li {
            padding: 0;
            margin-bottom: 0 !important;
        }

        a {
            display: flex;
            padding: rem(18px) rem(24px) rem(18px) rem(12px);
            min-width: rem(220px);
            font-size: rem(16px);
            color: $blue-2;
            align-items: center;

            i {
                margin-right: rem(12px);
                color: $white;
                background-color: $warning;
                height: 32px;
                width: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 52%;
                font-size: 14px;
            }

            &:hover {

                background-color: $gray-f6f;
            }
        }
    }

    &:hover {
        .jodropdown {
            &-list {
                transform: scaleY(1);
            }
        }
    }

    &--right {
        .jodropdown {
            &-list {
                left: unset;
                right: 0;

                li {
                    padding: 0;
                }
            }
        }

    }

    &--sm {
        .jodropdown {

            &-list {
                a {
                    padding: rem(12px) rem(16px) rem(12px) rem(16px);
                    min-width: rem(160px);
                    font-size: rem(14px);
                }

            }
        }
    }
}

.woocommerce-account .woocommerce .jodropdown ul li a:hover {
    background-color: $gray-f6f !important;
    color: $primary !important;
}

.circle-icon-box {
    width: rem(32px);
    height: rem(32px);
    border-radius: 50%;
    border: 1px solid $info;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $info;
    color: $white;
}