.card-imgtext {

    display: flex;
    align-items: center;

    &-wrraper {
        @media #{$max-md} {
            margin-bottom: rem(32px);
        }
    }

    &-img {
        flex: 0 0 rem(60px);
        height: rem(60px);
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $blue;
        margin-right: rem(6px);

        img {
            max-width: rem(32px);

            @media #{$max-sm} {
                max-width: rem(24px);
            }
        }

        @media #{$max-sm} {
            flex: 0 0 rem(54px);
            height: rem(54px);
        }
    }

    &-text {
        flex: 1;
        padding-left: rem(15px);
    }

    &-title {
        font-size: 1.3rem;
        font-weight: bold;
        margin-bottom: 0;
        letter-spacing: rem(-1px);
        color: $white;

        @media #{$max-sm} {
            font-size: rem(18px);
        }
    }
}

.entry-summary {
    .card-imgtext {


        &-wrraper {
            @media #{$max-md} {
                margin-bottom: rem(32px);
            }
        }

        &-img {
            flex: 0 0 rem(80px);
            height: rem(80px);
            background-color: transparent;

            img {
                max-width: 2.5rem;

                @media #{$max-sm} {
                    max-width: rem(32px);
                }
            }

            @media #{$max-sm} {
                flex: 0 0 rem(64px);
                height: rem(64px);
            }
        }

        &-text {
            flex: 1;
            padding-left: rem(15px);
        }

        &-title {
            font-size: 0.925rem;
            font-weight: normal;
            letter-spacing: rem(-0.6px);
            color: $primary;
            line-height: 1.222;
            font-weight: 500;

            @media #{$max-sm} {
                font-size: rem(14px);
            }
        }
    }


}