.preloader {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 99999;
    background-color: $primary;
    display: flex;
    align-items: center;
    justify-content: center;

    &-body {
        width: rem(160px);
        display: flex;
        flex-wrap: wrap
    }

    img {
        flex: 0 0 100%;
        min-width: 100%;
        margin-bottom: rem(12px);
        animation: rotate360 8s infinite;
    }

    &-text {
        font-size: rem(18px);
        color: $white;
        flex: 0 0 100%;
        animation: opacitychange 3s ease-in-out 0.66s infinite;
    }
}


@keyframes rotate360 {

    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes opacitychange {

    0%,
    100% {
        opacity: 0;
    }

    60% {
        opacity: 1;
    }
}