.s-product-promo {

    position: relative;
    padding-bottom: rem(32px);
    padding-top: rem(32px);
    margin-bottom: rem(28px);

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: -50vw;
        width: 200vw;
        height: 100%;
        background-color: $gray-f6f;
    }

    &-title {
        font-size: rem(28px);
        line-height: 1.21;
        font-weight: bold;
        color: $primary;
        margin-bottom: rem(16px);
        position: relative;
        z-index: 2; 
    }

    ul.products {

        // overflow: visible;
        padding: rem(16px) 0;

        @media #{$max-md} {
            // overflow: hidden;
        }

        .swiper-slide li.product {
            width: 100% !important;
            margin: 0;
            height: 100%;
        }
    }

    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev,
    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
        color: $blue;
    }

    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
        @media #{$sm} {
            right: rem(-42px);
        }

        @media #{$lg} {
            display: none;
        }
    }

    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
        @media #{$sm} {
            left: rem(-42px);
        }
        @media #{$lg} {
            display: none;
        }
    }
}