.xoo-wsc-modal {

    .xoo-wsc-container,
    .xoo-wsc-slider {
        max-width: rem(440px);
        right: rem(-440px);
    }

    &.xoo-wsc-cart-active {

        .xoo-wsc-container,
        .xoo-wsc-slider {
            right: 0;
        }

        .xoo-wsc-basket {
            right: rem(450px);
        }
    }

    .xoo-wsc-header {
        background-color: $blue-2;
        color: $white;
    }

    .xoo-wsc-img-col {
        flex: 0 0 20%;
        min-width: 20%;
    }

    .xoo-wsc-pname {
        a {
            color: $primary;
            font-size: rem(16px);
            font-weight: 700;
            line-height: 1;
        }
    }

    .xoo-wsc-qty-price {
        font-size: rem(10px);
        color: $gray-6c6;

        &>span:first-child {
            background-color: $warning;
            font-size: rem(12px);
            color: $white;
            display: inline-flex;
            padding: 2px 5px;
            border-radius: 8px;
            margin-right: 2px;
        }
    }

    .xoo-wsc-body span.amount {
        font-size: rem(12px);
        color: $gray-6c6;
        margin-left: 2px;
    }

    span.xoo-wsc-smr-del {
        height: 40px;
        width: 40px;
        background-color: $red;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white;
        border-radius: 50%;
        border: 2px solid $red;
        transition-duration: .15s;

        &:hover {
            background-color: $white;
            color: $red;
        }
    }

    .xoo-wsc-sm-left {
        padding-right: rem(24px);
    }

    .xoo-wsc-ft-totals {
        margin: 0 -20px;
        width: auto;
    }

    .xoo-wsc-ft-amt {
        width: 100%;
        padding: 6px 20px;
        display: flex;
        justify-content: space-between;
    }

}

.xoo-wsc-ft-buttons-cont {
    display: flex;
    flex-wrap: wrap;
    a.button {
        text-decoration: none;
        font-size: rem(16px);
        flex: 0 0 100%;
        min-width: 100%;
        &:hover {
            text-decoration: none !important;
        }
    }
    // .xoo-wsc-ft-btn-cart {
    //     flex: 0 0 50%;
    //     min-width: 50%
    // }
    // .xoo-wsc-ft-btn-info {
    //     flex: 0 0 50%;
    //     min-width: 50%
    // }

    // .xoo-wsc-ft-btn-checkout {
    //     padding: 0;
    // }
}

.xoo-wsc-basket {
    display: none !important;
}