.card-updown {

    display: flex;
    align-items: center;
    border-radius: rem(60px);
    overflow: hidden;
    background-color: $white;
    border: 1px solid $gray-d8d;
    // padding: 0 rem(12px);

    &-input {
        width: rem(80px);
        border: none !important;
        background-color: transparent;
        font-size: rem(18px);
        text-align: center;
        flex: 1;
    }

    &-btn {
        height: rem(20px);
        width: rem(20px);
        flex: 0 0 rem(20px);
        padding: 0;
        background-color: transparent;
        border-radius: 100% !important;
        font-size: rem(12px);
        margin: 0 rem(7px);
        transition-duration: .3s;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1;

        &:disabled {
            opacity: 0.4;
            cursor: not-allowed;

        }

        &:hover {
            background-color: $info;
            color: $white;

            &:disabled {
                &:hover {
                    background-color: $gray-6c6;
                }
            }
        }



        &--down {}

        &--up {}
    }
}