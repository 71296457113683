.woocommerce {
    position: relative;

}

.login-bg {
    background-image: url("../images/login-bg.png");
    height: 100%;
    width: 100vw;
    position: absolute;
    z-index: 0;
    background-repeat: no-repeat;
    background-size: cover;
    left: calc((100vw - 100%)/2 * -1);
}

.woocommerce-form-login {}

.login-body {

    position: relative;
    z-index: 1;
    color: $white;
    flex: 0 0 100%;
    padding: rem(120px) 0;

    @media #{$max-sm} {
        padding: rem(60px) 0 rem(80px);
    }

    h2 {
        position: relative;
        z-index: 1;
        color: $white;

        @media #{$max-sm} {
            margin-bottom: rem(16px);
            font-size: rem(28px);
        }
    }

    a:not(.btn) {
        color: $info;
    }


}

.login-form-box {
    background-color: rgba($primary, 0.169);
    box-shadow: 0 0 10px 2px rgba($black, 0.149);
    padding: rem(32px);
    backdrop-filter: blur(4px);
}

.woocommerce-account {
    .woocommerce-notices-wrapper {
        flex: 0 0 100%;
        padding-top: 0;
    }

    .woocommerce {
        padding: 0;
    }
}

.login {
    .mo-openid-app-icons {


        &>p {
            color: $white !important;
            margin-bottom: rem(14px);
        }

    }
}