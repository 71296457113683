footer {
    background-color: $primary;
    color: $white;
    padding-top: rem(40px);
    padding-bottom: rem(16px);

    a {
        color: $white;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;

        a {
            font-size: rem(14px);
            line-height: rem(18px);
        }

        li {
            margin-bottom: rem(14px);
        }
    }

    h2 {
        color: $info;
        font-size: rem(18px);
        line-height: rem(20px);
        text-transform: uppercase;
        font-weight: bold;
        margin-bottom: rem(18px);
    }

    a {
        &:hover {
            color: $info;
        }
    }
}

img.footer-logo {
    max-width: rem(224px);
    margin-left: rem(46px);
    margin-bottom: rem(16px);
}

.sc {
    a {
        font-size: rem(18px);
        margin-right: rem(24px);
    }
}

.copyright-wrapper {
    margin-top: rem(28px);
    display: flex;

    @media #{$max-sm} {
        flex-wrap: wrap;
    }


    &>ul {
        display: flex;
        flex: 1;
        justify-content: center;
        flex-wrap: wrap;
        max-width: rem(980px);
    }

    .menu {
        padding: 0;
        margin-left: rem(-130px);
        display: none;

        ul {
            display: flex;

            a {
                font-size: rem(14px);
            }
        }

        li {
            margin-bottom: 0;
            padding: rem(12px) rem(16px);
        }
    }

    .copyright {
        flex: 0 0 rem(260px);
        font-size: rem(14px);
        line-height: rem(38px);

        @media #{$max-sm} {
            flex: 0 0 100%;
            order: 1;
            text-align: center;
        }

    }
}

.menu-footer-menu-container {
    display: flex;
    
    @media #{$max-sm} {
        margin-bottom: rem(16px);
    }

    li {
        margin-right: rem(32px);

        @media #{$max-sm} {
            margin: 0 rem(15px);
        }

        &:last-child {
            margin-right: 0;

            @media #{$max-sm} {
                margin: 0 rem(15px);
            }
        }
    }
}