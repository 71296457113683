.banner {
    color: $white;
    font-size: rem(16px);
    text-align: center;
    line-height: rem(29px);
    font-weight: 500;
    padding: rem(5px) 0;
    position: fixed;
    left: 0;
    width: 100vw;
    z-index: 1000;
    top: 0;

    @media #{$max-md} {
        padding: rem(5px) rem(20px);
        display: none;
    }

    p {
        @media #{$max-sm} {
            font-size: rem(14px);
            line-height: 1.2;
        }
    }

    &-primary {
        background-color: $primary;
    }

    &-close {
        position: absolute;
        right: rem(15px);
        top: 50%;
        transform: translateY(-50%);
        font-size: rem(22px);
        font-weight: 400;
        cursor: pointer;
        border-radius: 50%;
        height: rem(22px);
        width: rem(22px);
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: rem(1px);
        transition-duration: .3s;

        @media #{$max-sm} {
            right: rem(-12px);
            top: rem(7px);
        }

        &:hover {
            background-color: lighten($primary, 4%);

        }
    }

    .container {
        position: relative;
    }
}

.header {
    position: fixed;
    width: 100vw;
    left: 0;
    top: rem(39px);
    background-color: $white;
    z-index: 1000;
    box-shadow: rem(-2px) rem(1px) rem(3px) rem(0px) rgba($black, 0.169);

    @media #{$max-xl} {
        top: rem(34px);
    }

    @media #{$max-md} {
        top: 0;
        padding-left: rem(30px);
        width: 100%;
    }

    .btn-toggle-menu {
        background-color: transparent;
        border: none;
        color: $primary;
        position: absolute;
        left: rem(15px);
        top: 50%;
        font-size: rem(20px);
        transform: translateY(-50%);

        @media #{$lg} {
            display: none;
        }
    }

}

.notopbanner {
    .header {
        top: rem(0px);
        left: 0;
        width: 100vw;
        transition-duration: .3s;
    }
}

.open-menu-mobile {
    .menu {
        &-left {
            opacity: 1;
            pointer-events: all;
            transition-delay: 0s;

            .main-nav {
                @media #{$max-md} {
                    transform: translateX(0);
                    transition-delay: .2s;
                    background-image: url(../images/bg-menu-mb.png);
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;
                }
            }

        }
    }
}

.menu {
    display: flex;
    align-items: center;
    padding: rem(12px) 0;

    h1 {
        margin-bottom: 0;
    }

    &-left {
        flex: 1;

        @media #{$max-md} {
            background-color: rgba($black, 0.2);
            position: fixed;
            top: rem(62px);
            left: 0;
            height: calc(100vh - #{rem(62px)});
            width: 100%;
            z-index: 3;
            opacity: 0;
            pointer-events: none;
            transition-duration: .2s;
            transition-delay: .4s;
        }

        .main-nav {
            @media #{$max-md} {
                height: 100%;
                background-color: $white;
                width: 100%;
                max-width: rem(280px);
                padding: rem(60px) rem(0px);
                box-shadow: rem(10px) 0 rem(5px) rem(-5px) rgba($black, 0.31);
                transform: translateX(-100%);
                transition-duration: .6s;
                transition-delay: 0s;
            }
        }
    }

    &-center {
        flex: 0 0 rem(180px);
        padding: 0 rem(15px);

        @media #{$max-md} {
            flex: 0;
        }
    }

    &-right {
        flex: 1;
        align-items: center;
        justify-content: flex-end;
    }

    &-logo {
        display: block;
        width: 100%;

        @media #{$max-md} {
            width: rem(125px);
        }

        @media #{$max-md} {
            width: rem(100px);
        }

        img {
            width: 100%;
        }
    }

    .btn-call {

        @media #{$max-md} {
            height: rem(50px);
            width: rem(50px);
            padding: 0;
            min-width: unset;
            display: flex;
            align-items: center;
            justify-content: center;

            display: none;
        }

        span {
            @media #{$max-sm} {
                display: none;
            }
        }

        &-mb {
            position: absolute;
            bottom: rem(100px);
            font-size: rem(14px);
            color: $white;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            max-width: rem(220px);
            border-radius: rem(100px);
            margin: 0;

            i {
                margin-right: rem(14px);
            }
        }
    }

    &-items {
        display: flex;
        list-style: none;
        padding: 0;
        margin: 0;

        .menu-header-menu-container {
            display: flex;
            align-items: center;
            margin-left: rem(-32px);

            @media #{$max-md} {
                flex-wrap: wrap;
                margin-left: 0;
            }

        }

        li {
            padding: 0 rem(32px);
            position: relative;

            @media #{$max-md} {
                flex: 0 0 100%;
            }


            @media #{$max-md} {
                padding: rem(0px);
            }

            &::after {
                content: "";
                display: inline-block;
                height: rem(8px);
                width: rem(8px);
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%) translateX(-50%);
                background-color: $light;
                border-radius: 50%;


                @media #{$max-md} {
                    right: unset;
                    left: 0;
                    transform: translateY(-50%) translateX(rem(15px));
                    background-color: $primary;
                    content: none;
                }
            }

            &:last-child {
                &::after {
                    content: none;

                }

            }
        }

        a {
            font-weight: 600;

            @media #{$max-md} {
                padding: rem(10px) rem(20px) rem(10px) rem(30px);
                display: block;
            }
        }
    }

    &-items-right {
        display: flex;
        list-style: none;
        padding: 0;
        margin: 0;
        justify-content: flex-end;
        align-items: center;
        margin-right: rem(-12px);

        li {
            padding: 0 rem(12px);


            @media #{$max-md} {
                padding: 0 rem(5px);
            }
        }
    }

    &-cart {
        color: $primary;
        height: rem(56px);
        width: rem(56px);
        border-radius: 50%;
        border: 1px solid transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        font-size: rem(24px);
        cursor: pointer;

        @media #{$max-sm} {
            font-size: rem(20px);
            height: rem(40px);
            width: rem(40px);
        }

        &:hover {
            border-color: $primary;
        }

        &>sup {
            position: absolute;
            top: rem(7px);
            right: rem(6px);
            font-size: rem(9px);
            height: rem(16px);
            width: rem(16px);
            display: inline-block;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $info;
            border-radius: 50%;
            color: $white;
        }

        @media #{$max-sm} {
            font-size: rem(20px);
        }
    }

    &-user {
        color: $primary;
        height: rem(56px);
        width: rem(56px);
        border-radius: 50%;
        border: 1px solid transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        font-size: rem(24px);


        @media #{$max-sm} {
            font-size: rem(20px);
            height: rem(40px);
            width: rem(40px);
        }

        &:hover {
            border-color: $primary;
        }
    }

    &-mb-bottom {
        display: none;
        padding: rem(10px);
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;

        @media #{$max-md} {
            display: flex;
            align-items: center;
        }

        ul {
            @media #{$max-md} {
                display: flex;
                align-items: center;
                padding: rem(10px);
                justify-content: center;
                padding: 0;
                margin: 0;
                list-style: none;
            }
        }

        a {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            margin: 0 rem(5px);
            font-size: rem(20px);
            color: $primary;
        }

        .copyright {
            font-size: 12px;
            font-weight: 600;
            margin-right: 10px;
            flex: 1;
            color: $primary;

        }
    }
}

body.woocommerce-checkout {
    padding-top: 0;

    .header {
        position: unset;
    }
}