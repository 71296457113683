.page-nossolution {
    margin-bottom: rem(64px);

    h1 {
        color: $primary;
    }

    p {
        font-size: rem(16px);
        color: $gray-6c6;

        @media #{$max-sm} {
            font-size: rem(14px);
        }

    }

    &-disc {
        // max-width: rem(1072px);

        img {
            max-width: unset !important;
            width: 100%;

            @media #{$max-sm} {
                border-radius: 10px;

            }
        }
    }


}