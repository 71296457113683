.s-pneumatiques {
    background-color: $primary;
    padding: 2rem 0;
    // margin-bottom: 50px;

    @media #{$max-xl} {
        padding: 2.5rem 0;
    }

    @media #{$max-md} {
        padding: rem(50px) rem(0px);
    }

    &-row {
        padding: 0 rem(40px);

        @media #{$max-md} {
            padding: 0;
        }
    }

    &-navtab {

        @media #{$max-md} {
            margin-top: rem(32px);
        }
        @media #{$max-md} {
            margin-top: rem(25px);
        }
    }

    &-title {
        font-size: rem(28px);
        line-height: rem(32px);
        font-weight: bold;
        color: $white;
        margin-bottom: rem(4px);

        @media #{$max-md} {
            font-size: rem(32px);
            line-height: rem(39px);
        }
        @media #{$max-sm} {
            font-size: rem(22px);
            line-height: 1.3;
            margin-bottom: rem(16px);
        }

        strong {
            color: $warning;
            font-weight: bold;
        }
    }

    &-disc {
        font-size: rem(16px);
        color: $white;
        line-height: rem(24px);

        @media #{$max-md} {
            font-size: rem(14px);
            line-height: rem(14px);
        }
    }

    .nav-item {

        margin-right: rem(8px);

        @media #{$max-md} {
            margin-right: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    .nav-link {
        font-size: rem(18px);
        line-height: rem(22px);
        color: $white;
        border-radius: rem(50px);
        padding: rem(8px) rem(24px);


        @media #{$max-md} {
            font-size: rem(18px);
            padding: rem(7px) rem(22px);
        }

        @media #{$max-sm} {
            font-size: rem(16px);
            padding: rem(6px) rem(16px);
        }

        &.active {
            background-color: $info;
        }
    }

    .products-slider {
        padding: rem(18px) rem(0px) rem(5px);
        // overflow: visible;
    }

    .tab-pane {
        position: relative;
        padding: rem(0px) rem(40px);

        @media #{$max-sm} {
            padding: 0 rem(10px);
        }
    }
    .tab-content {
        margin-bottom: rem(28px);
    }


    .container {

        @media #{$xl} {

            max-width: rem(1300px);
        }

        @media #{$xxl} {
            max-width: rem(1546px);
        }
    }
}