.woocommerce-page .woocommerce-pagination {
    display: flex;
    justify-content: center;
    margin-top: rem(34px);
    margin-bottom: rem(64px);

}

.woocommerce-pagination ul.page-numbers,
.woocommerce-page .woocommerce-pagination ul.page-numbers,
.shop_page_number.page-numbers {
    display: flex;
    align-items: center;

    li {
        padding: 0 rem(5px);

        @media #{$max-sm} {
            display: none;

            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(9),
            &:nth-child(11),
            &:nth-child(12),
            &:nth-child(13) {
                display: block;
            }
        }
    }

    .next.page-numbers,
    .prev.page-numbers,
    a.page-numbers,
    span.page-numbers {
        padding: 0;
        height: rem(40px);
        min-width: rem(40px);
        padding: rem(5px);
        display: flex;
        align-items: center;
        justify-content: center;
        color: $gray-6c6;

        &:hover {
            background-color: $cyan;
            color: $white;
        }

        &.current {
            color: $black;


            &:hover {
                background-color: transparent
            }

        }
    }

    .prev.page-numbers,
    .next.page-numbers {
        font-size: 0;
        position: relative;
        background-color: $warning;
        color: $white;



        &::after {
            font-family: 'Font Awesome 5 Free';
            font-weight: 900;
            font-size: rem(18px);
        }
    }

    .prev.page-numbers {
        &::after {
            content: "\f053";
        }
    }

    .next.page-numbers {
        &::after {
            content: "\f054";
        }
    }
}

.shop_page_number.page-numbers {
    width: 100%;
    justify-content: center;
    margin-top: rem(40px);
    margin-bottom: rem(60px);
}