ul.products li.product {
    .woocommerce-loop-product__link {
        box-shadow: 0 0 rem(14px) rem(-4px) rgba($black, 0.341);
        border-radius: rem(8px);
        position: relative;
        padding: rem(10px) rem(15px);
        // height: 461px;
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        align-items: center;
        width: 100%;
        height: 100%;
        background-color: $white;

        &:hover {
            color: $warning;
        }

        img {
            max-width: rem(192px) !important;
            max-height: rem(192px) !important;

            @media #{$max-sm} {
                max-width: rem(230px) !important
            }
        }

        .promo-garantier {
            position: absolute;
            top: rem(15px);
            left: 0;
        }

        .pourcentage {
            background-color: $warning;
            padding: rem(4px) rem(14px);
            color: $white;
        }

        &>ul {
            order: 3;
            display: flex;
            width: 100%;
            list-style: none;
            padding: 0;
            // min-height: 24px;


            li {
                padding-right: rem(15px);
                font-weight: bold;
                color: $blue-2;
                position: relative;

                &::after {
                    content: "";
                    height: rem(4px);
                    width: rem(4px);
                    position: absolute;
                    right: rem(5.5px);
                    top: 50%;
                    transform: translateY(-50%);
                    background-color: $warning;
                    border-radius: rem(5px);
                }

                &:last-child {
                    &::after {
                        content: none;
                    }
                }
            }
        }

        &>.sub-title {
            order: 4;
            margin-top: rem(11px);
            width: 100%;
            font-size: rem(14px);
            letter-spacing: rem(0.1px);
            color: $gray-6c6;
            // min-height: 38px;
        }

        .price {
            order: 5;
            margin-bottom: 0;
            width: 100%;
            margin-top: rem(4px);
            font-size: rem(28px);
            font-weight: 700;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            color: $primary;
            flex: 1;

            del {
                order: 2;
                color: $gray-6c6;
                opacity: 1;
                font-size: rem(20px);
                margin-right: rem(20px);
                margin-bottom: rem(2px);
                text-decoration: none;
                position: relative;

                &::after {
                    content: "";
                    position: absolute;
                    top: 44%;
                    height: 1.1px;
                    left: 0;
                    background-color: $warning;
                    width: 100%;
                }

                .woocommerce-Price-currencySymbol {
                    font-size: rem(12px);
                }
            }

            ins {
                order: 1;
            }

            .woocommerce-Price-currencySymbol {
                font-size: rem(16px);
                font-weight: 400;
                margin-left: rem(8px);
                color: $gray-6c6;
            }
        }
    }


}

.woocommerce ul.products {
    flex-wrap: wrap;

    li.product img.attachment-woocommerce_thumbnail,
    li.product img.woocommerce-placeholder {
        height: auto !important;
        width: rem(244px) !important;
        /* object-fit: contain; */
        margin-bottom: rem(20px);
        order: 1;
    }

    .swiper-wrapper {
        justify-content: center;

        @media #{$max-sm} {
            justify-content: flex-start;
        }
    }

    &.columns-3 {
        margin: 0 rem(-8px);

        li.product {
            flex: 0 0 33.33%;
            min-width: 33.33%;
            width: unset !important;
            margin: 0;
            margin-bottom: rem(16px);
            padding: 0 rem(8px);
            height: unset;

            @media #{$max-lg} {
                flex: 0 0 50%;
                min-width: 50%;
            }

            @media #{$max-sm} {
                flex: 0 0 100%;
                min-width: 100%;
            }
        }
    }
}

ul.products li.product {
    height: 100%;

    .woocommerce-loop-product__title {
        font-size: rem(20px);
        font-weight: bold;
        width: 100%;
        letter-spacing: rem(2.5px);
        word-spacing: rem(-6.4px);
        order: 2;
        margin-bottom: rem(2px);
        margin-top: rem(16px);

        &~ul {
            // margin-top: 63px;
        }
    }
}

.filter-toggle {
    position: fixed;
    bottom: rem(20px);
    left: rem(20px);
    color: $white;
    min-width: rem(124px);
    padding: rem(11px) rem(26px);
    z-index: 999;

    @media #{$lg} {
        display: none;
    }

    i {
        margin-right: rem(10px);
    }

    &--close {
        position: absolute;
        top: 8px;
        right: 10px;
        /* height: auto; */
        bottom: unset;
        left: unset;
        width: 30px;
        height: 30px;
        padding: 0;
        min-width: unset;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        border-radius: 50%;
        background-color: transparent; 
        i {
            margin-right: 0;
        }
    }
}

.woocommerce .woocommerce-ordering,
.woocommerce-page .woocommerce-ordering {
    width: rem(350px);
}


.related.products {
    margin-bottom: 0.8rem;
    padding-top: 0.4rem;
    flex: 0 0 100%;
    min-width: 100%;

    @media #{$max-sm} {
        margin-bottom: 0.6rem
    }

    &>h2 {
        font-size: 2.375rem;
        line-height: 2.9375rem;
        font-weight: bold;
        text-align: center;
        color: $blue;
        margin-bottom: rem(32px);

        @media #{$max-sm} {
            font-size: rem(26px);
            line-height: 1.2;
            margin-bottom: rem(22px);
        }

    }
}