.swiper-button-next:after,
.swiper-button-prev:after {
    font-size: rem(24px);

    @media #{$max-sm} {
        // font-size: rem(16px);
    }
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
    left: rem(-11px);
    color: $white;
    width: rem(42px);
    height: rem(42px);
    border-radius: 100%;
    transition-duration: .3s;

    @media #{$max-sm} {
        left: rem(-25px)
    }

    &:hover {
        background-color: rgba($white, 0.075)
    }
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
    right: rem(-11px);
    color: $white;
    width: rem(42px);
    height: rem(42px);
    border-radius: 100%;
    transition-duration: .3s;

    @media #{$max-sm} {
        right: rem(-25px)
    }

    &:hover {
        background-color: rgba($white, 0.075)
    }
}


.swiper-slide {
    height: auto;
}