.s-nos-solustion {
    padding: 1.1rem 0;

    @media #{$max-sm} {
        padding: rem(24px) 0;
    }

    &-title {
        margin-bottom: rem(8px);
    }

    &-stitle {
        margin-bottom: 0;
        font-size: rem(16px);
        line-height: 1.5;
        color: $gray-6c6;
    }
    &-col  {
        padding: rem(40px);
    }
}