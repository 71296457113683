.s-avantages {
    padding-top: 1rem !important;
    padding-bottom: 2rem !important;

    @media #{$max-sm} {
        padding-top: rem(32px);
        padding-bottom: rem(38px);
    }

    &>.container {
        max-width: rem(1316px);

        @media #{$max-xl} {
            max-width: rem(1220px);
        }

        @media #{$max-md} {
            max-width: rem(720px);
        }
    }

    &-cta {
        margin-top: rem(48px);
        display: flex;
        justify-content: center;

        @media #{$max-md} {
            margin-top: rem(55px);
            margin-bottom: rem(11px);
        }
    }

    .col-md-4 {
        &:last-child {
            .card-avantages-icright {
                display: none;
            }
        }
    }
}