.woocommerce-account {
    .woocommerce {
        display: flex;
        flex-wrap: wrap;
        padding-top: rem(32px);
        padding: 0;
        padding-bottom: rem(64px);

        @media #{$max-sm} {
            padding-bottom: 0;
        }

        .woocommerce-MyAccount-navigation {
            flex: 0 0 20%;
            min-width: 20%;
            margin: 0;

            @media #{$max-md} {
                flex: 0 0 30%;
                min-width: 30%;
            }

            @media #{$max-sm} {
                flex: 0 0 100%;
                min-width: 100%;
            }
        }

        .woocommerce-MyAccount-navigation {
            ul {
                li {
                    margin: 0;
                    margin-bottom: rem(10px) !important;
                    padding: 0 !important;

                    @media #{$max-sm} {
                        margin-bottom: rem(10px) !important;
                    }

                    a {
                        font-size: rem(18px);
                        background-color: $gray-f3f;
                        display: block;
                        padding: rem(12px) rem(16px);

                        &:hover {
                            background-color: $gray-d8d;
                            text-decoration: none !important;
                        }
                    }

                    &.is-active {
                        a {
                            background-color: $primary;
                            color: $white !important;
                            text-decoration: none;
                        }
                    }
                }
            }

        }

        .woocommerce-MyAccount-content {
            flex: 0 0 80%;
            min-width: 80%;
            padding-left: rem(40px);
            margin: 0;

            @media #{$max-md} {
                flex: 0 0 70%;
                min-width: 70%;
            }

            @media #{$max-sm} {
                flex: 0 0 100%;
                min-width: 100%;
                padding-left: rem(00px);
            }

            p {
                font-size: rem(18px);
            }

            .woocommerce-Addresses {
                .woocommerce-Address-title {
                    h3 {
                        color: $primary;
                        font-weight: 600;
                        margin-top: 0;
                    }

                    .edit {
                        font-size: rem(14px);
                    }
                }

                address {
                    margin-bottom: 0;
                }
            }

            label {
                justify-content: flex-start;
                font-size: rem(18px);
                color: $gray-600;
                display: block;
                flex: 0 0 100%;

                @media #{$max-sm} {
                    font-size: rem(14px);
                }

            }

            em {
                font-size: rem(12px);
                line-height: 1.2;
                display: inline-block;
                color: $gray-700;
            }

            input {
                font-size: rem(20px);

                @media #{$max-sm} {
                    font-size: rem(16px);
                }
            }

            h3 {
                color: $primary;
                font-weight: 600;
                margin-top: 0;

                @media #{$max-sm} {
                    font-size: rem(16px);
                    margin-right: 100px;
                }
            }
        }

        .woocommerce-EditAccountForm input {
            border: 1px solid #ced4da;
        }
    }

    .select2-container .select2-selection--single .select2-selection__rendered,
    .woocommerce-account .woocommerce-MyAccount-content .woocommerce-address-fields .select2-selection .select2-selection__rendered {
        min-height: rem(60px);
        font-weight: 500;
        color: #495057;
        font-size: rem(16px);
        min-height: rem(48px)
    }

    .woocommerce-MyAccount-content .woocommerce-address-fields .woocommerce-address-fields__field-wrapper {
        display: flex;
        flex-wrap: wrap;
        margin: 0 rem(-15px);

        @media #{$max-sm} {
            margin: 0;
            padding: 0 rem(5px);
        }
    }

    .woocommerce-MyAccount-content .woocommerce-address-fields .form-row {
        flex: 0 0 50%;
        min-width: 50%;
        padding: 0 rem(15px);
        align-items: flex-end;

        @media #{$max-sm} {
            flex: 0 0 100%;
            min-width: 100%;
            margin: 0;
            padding: 0;
        }
    }

    .select2-container--default .select2-selection--single .select2-selection__arrow b {
        top: rem(32px);
        right: rem(10px);
        left: unset;
    }

    .woocommerce-MyAccount-content .woocommerce-address-fields #billing_company_field {
        padding-top: 0rem !important;
    }

    .woocommerce-MyAccount-content {
        padding-bottom: rem(60px);
    }

    table.account-orders-table {
        @media #{max-md} {
            width: 100%;
        }
    }

    .woocommerce .woocommerce-Address a.edit {
        width: auto;
    }
}

.woocommerce-account.woocommerce-lost-password {
    .woocommerce {
        justify-content: center;
    }
}

.card-address {
    border: 2px solid $gray-d8d;
    padding: rem(20px) rem(20px);
}

.woocommerce-account .woocommerce-MyAccount-content .woocommerce-address-fields input {
    border: 1px solid #ced4da;
}

.woocommerce-account .woocommerce-MyAccount-content .woocommerce-address-fields .selection,
.woocommerce-account .woocommerce-MyAccount-content .woocommerce-address-fields input {
    font-size: 1.5rem;
    padding-top: .3rem;
    padding-bottom: .3rem;
}

.woocommerce form .form-row .input-text,
.woocommerce-page form .form-row .input-text {
    box-sizing: border-box;
    width: 100%;
}


.woocommerce-input-wrapper {
    display: block;
    flex: 0 0 100%;
    position: relative;

}

.woocommerce-account .woocommerce-MyAccount-content .woocommerce-address-fields .select2-selection {
    margin-top: 0;
}

.woocommerce-account .woocommerce-MyAccount-content .woocommerce-address-fields .select2-selection {
    padding-top: 0;
    height: auto;
    border: 1px solid #ced4da;
    ;
}