.s-why {
    padding-bottom: 1.2rem;
    padding-top: 2rem;

    &-title {
        font-weight: bold;
        color: $primary;
        text-align: center;
        margin-bottom: rem(32px);

        @media #{$max-sm} {

            font-size: rem(28px);
            line-height: rem(32px);
        }

        strong {
            color: $warning;
            font-weight: bold;
        }
    }
}