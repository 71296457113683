.page-content {
    &-body {
        background-color: $white;
        max-width: rem(1198px);
        margin-top: rem(-326px);
        margin-left: auto;
        margin-right: auto;
        padding: rem(22px) rem(114px) rem(64px);
        box-shadow: rem(0px) rem(16px) rem(32px) rgba($black, 0.078);


        @media #{$max-md} {
            padding: rem(22px) rem(80px) rem(56px);
            margin-top: rem(-160px);
        }

        @media #{$max-sm} {
            padding: rem(16px) rem(16px) rem(22px);
            margin-top: rem(-60px);
        }

    }

    h1,
    .h1 {
        font-size: rem(52px);
        line-height: 1.211;
        margin-bottom: rem(24px);
        font-weight: bold;
        display: block;
        color: $black;

        @media #{$max-md} {
            font-size: rem(42px);
            margin-bottom: rem(20px);
        }


        @media #{$max-sm} {
            font-size: rem(28px);
            margin-bottom: rem(20px);
        }
    }

    h2,
    .h2 {
        font-size: rem(38px);
        line-height: 1.236;
        margin-bottom: rem(24px);
        font-weight: bold;
        display: block;
        color: $black;

        @media #{$max-md} {
            font-size: rem(42px);
            margin-bottom: rem(20px);
        }

        @media #{$max-sm} {
            font-size: rem(22px);
        }
    }

    h3,
    .h3 {
        font-size: rem(32px);
        line-height: 1.218;
        margin-bottom: rem(24px);
        font-weight: bold;
        display: block;
        color: $black;

        @media #{$max-md} {
            font-size: rem(28px);
            margin-bottom: rem(20px);
        }

        @media #{$max-sm} {
            font-size: rem(18px);
        }
    }

    h4,
    .h4 {
        font-size: rem(24px);
        line-height: 1.208;
        margin-bottom: rem(24px);
        font-weight: bold;
        display: block;
        color: $black;

        @media #{$max-md} {
            font-size: rem(18px);
            margin-bottom: rem(20px);
        }

        @media #{$max-sm} {
            font-size: rem(14px);
        }
    }

    ul {
        padding: 0;
        margin: 0;
        list-style: none;
        margin-bottom: rem(32px);

        li {
            padding-left: rem(32px);
            position: relative;
            font-weight: 600;
            margin-bottom: rem(22px);

            @media #{$max-md} {
                font-size: rem(14px);
                margin-bottom: rem(18px);
            }

            @media #{$max-sm} {
                font-size: rem(13px);
            }

            &:last-child {
                margin-bottom: 0;
            }

            &::before {
                content: "";
                position: absolute;
                left: rem(4px);
                top: rem(4px);
                height: rem(14px);
                width: rem(14px);
                border-radius: 100%;
                background-color: $warning;
            }
        }
    }

    .breadcrumbs {
        @media #{$max-sm} {
            padding: rem(4px) 0 rem(16px) 0;
        }
    }

    img {
        border-radius: 10px;
    }
}

.p1 {
    font-size: rem(18px);
    line-height: 1.555;
    font-weight: 500;
    margin-bottom: rem(32px);

    @media #{$max-md} {
        font-size: rem(16px);
        margin-bottom: rem(24px);
    }

    @media #{$max-sm} {
        font-size: rem(14px);
    }
}

.p2 {
    font-size: rem(16px);
    line-height: 1.5;
    font-weight: 500;
    margin-bottom: rem(32px);

    @media #{$max-md} {
        font-size: rem(14px);
        margin-bottom: rem(24px);
    }

    @media #{$max-sm} {
        font-size: rem(13px);
    }

}

.p3 {
    font-size: rem(14px);
    line-height: 1.571;
    font-weight: 500;
    margin-bottom: rem(32px);

    @media #{$max-md} {
        font-size: rem(12px);
        margin-bottom: rem(24px);
    }
}

.p-semibold {
    font-weight: 600;
}

.page-marque {
    .filter-form--inline {
        margin-top: rem(64px);
        margin-bottom: rem(32px);
    }
}