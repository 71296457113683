.s-discription {
    background-color: $blue-2;
    color: $white;
    padding: rem(16px) 0;
    position: relative;
    // margin-top: 70px;
    font-size: 0.8rem;

    @media #{$max-md} {
        padding: rem(16px) 0 rem(16px) 0;
    }

}