.s-banner {
    height: rem(300px);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &--lg {
        height: rem(550px);

        @media #{$max-md} {
            height: rem(380px);
        }

        @media #{$max-sm} {
            height: rem(200px);
        }

    }
}