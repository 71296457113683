.card-citation {
    padding-left: rem(90px);
    position: relative;
    margin-bottom: rem(32px);

    &::before {
        content: "";
        position: absolute;
        left: rem(4px);
        top: rem(4px);
        height: rem(47px);
        width: rem(62px);
        background-image: url(../images/svg/citation-icon.svg);
        background-size: contain;
        background-repeat: no-repeat;
    }

    p {
        font-style: italic;
        color: $gray-6c6;
    }
}