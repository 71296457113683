.card-marque {

    padding: rem(40px) rem(32px);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    @media #{$max-xl} {
        padding: rem(22px) rem(28px);
    }

    &:hover {
        img {
            filter: grayscale(0);
        }
    }

    img {
        filter: grayscale(1);
        transition-duration: .3s;
    }

}