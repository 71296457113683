.card-nos-solustion {
    border-radius: rem(8px);
    position: relative;
    overflow: hidden;
    padding: rem(24px);
    color: $white;
    min-height: 20rem;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    background-size: cover;
    height: 100%;

    @media #{$max-xl} {
        // min-height: 300px;
        // padding: 20px;
    }

    @media #{$max-sm} {
        min-height: rem(230px);
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(91.26deg, rgba($black, 0.702) 0.94%, rgba($black, 0) 98.84%);
        z-index: 1;
    }

    & &-bg {
        height: 100%;
        width: 100%;
        object-fit: cover;
        position: absolute;
        left: 0;
        top: 0;
    }


    &-info {
        position: relative;
        z-index: 2;
        flex: 0 0 100%;
    }

    &-footer {
        position: relative;
        z-index: 2;
        flex: 0 0 100%;
    }

    &-name {
        margin-bottom: rem(16px);
        display: flex;
        flex-direction: column;
        max-width: rem(312px);
    }

    &-title {
        font-size: rem(24px);
        font-weight: bold;
        line-height: 1.20;
        margin-bottom: rem(8px);
        margin-bottom: rem(8px);
    }

    &-stitle {
        font-size: rem(16px);
        line-height: rem(22px);
        line-height: 1.25;
        display: block;
    }
}

.bg-pneu {
    background-image: url(../images/bg-article.png);
    background-repeat: no-repeat;
    background-size: 100%;
}