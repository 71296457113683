/* Variables */
:root {

    // --global--color-background: #{$white}; 

}

html {
    font-size: 16px;
    overflow-x: hidden;

    @media #{$max-xl} {
        font-size: 12.8px;
    }

    @media #{$max-md} {
        font-size: 16px;
        overflow-x: hidden;
    }

}

body,
body.woocommerce {
    padding-top: rem(119px);

    @media #{$max-xl} {
        padding-top: rem(114px);
    }

    @media #{$max-md} {
        overflow-x: hidden;
        padding-top: rem(100px);
    }

    @media #{$max-md} {
        overflow-x: hidden;
        padding-top: rem(64px);
    }

    .select2-container,
    body .ui-widget.ui-datepicker {
        z-index: 999 !important;
    }

    &.notopbanner {
        padding-top: rem(80px);
    }
}


p {
    &:last-child {
        margin-bottom: 0;
    }
}

a {
    transition-duration: .3s;

    &:hover {
        text-decoration: none;
    }
}

h1 {
    font-size: rem(32px);
    font-weight: bold;
    line-height: 1.218;
    margin-bottom: rem(32px);
}

.h2,
h2 {
    font-weight: bold;
    color: $blue;
    margin-bottom: rem(32px);

    @media #{$max-sm} {
        font-size: rem(26px);
        line-height: 1.2;
        margin-bottom: rem(22px);
    }

    strong {
        color: $warning;
        font-weight: bold;
    }
}

button {
    transition-duration: .3s;
}

.select2-container .select2-selection--single {
    height: auto;
    border-color: $gray-100;

    .select2-selection__rendered {
        padding-top: rem(7px);
        padding-bottom: rem(7px);
        padding-left: rem(10px);
        padding-right: rem(10px);
        min-height: rem(48px);
        font-weight: bold;
        color: $black;
        display: flex;
        align-items: center;

        @media #{$max-md} {

            min-height: rem(45px);
        }
    }
}

.select2-container {
    width: 100% !important;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    top: 50%;
    transform: translateY(-50%);
    right: rem(6px);
}

.select2-container--default .select2-selection--single .select2-selection__clear {
    position: absolute;
    top: rem(-1px);
    right: rem(7px);
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
    border: none;
    height: auto;
    top: rem(4px);
    width: auto;

    &::after {
        content: "\f107";
        font-family: 'Font Awesome 5 Free';
        font-weight: 600;
        color: $black;
    }
}

.bapf_slidr_main.ui-widget-content .ui-slider-handle,
.berocket_filter_price_slider.ui-widget-content .ui-slider-handle,
.slide.default .bapf_slidr_main .ui-state-default,
.slide.default .bapf_slidr_main .ui-widget-header .ui-state-default,
.slide.default .bapf_slidr_main.ui-widget-content .ui-state-default,
.slide.default .berocket_filter_price_slider .ui-state-default,
.slide.default .berocket_filter_price_slider .ui-widget-header .ui-state-default,
.slide.default .berocket_filter_price_slider.ui-widget-content .ui-state-default {
    border: rem(2px) solid $primary;
    background-color: $white;
    height: rem(20px);
    width: rem(20px);
    top: rem(-8px);

    &:focus {
        outline: none;
    }
}

.bapf_slidr_main.ui-widget-content .ui-slider-range,
.berocket_filter_price_slider.ui-widget-content .ui-slider-range {
    background-color: $white;
}

.bapf_slidr_main.ui-widget-content,
.berocket_filter_price_slider.ui-widget-content {
    background-color: $white;
}

.col-xs-5ths,
.col-sm-5ths,
.col-md-5ths,
.col-lg-5ths {
    position: relative;
    min-height: rem(1px);
    padding-right: rem(15px);
    padding-left: rem(15px);
}

.col-xs-5ths {
    flex: 0 0 20%;
    min-width: 20%;
}

@media (min-width: 768px) {
    .col-sm-5ths {
        flex: 0 0 20%;
        min-width: 20%;
    }
}

@media (min-width: 992px) {
    .col-md-5ths {
        flex: 0 0 20%;
        min-width: 20%;
    }
}

@media (min-width: 1200px) {
    .col-lg-5ths {
        flex: 0 0 20%;
        min-width: 20%;
    }
}


.table-flex {
    display: flex;
    flex-direction: column;

    thead,
    tbody {
        display: flex;
        flex-direction: column;
    }

    tr {
        display: flex;
    }

    th,
    td {
        display: flex;
    }
}

// -------------------------------------------



.woocommerce-notices-wrapper {
    padding-top: rem(24px);
}

.woocommerce-info,
.woocommerce-message {
    background-color: $light;
    border-radius: 4px;
    color: #6c6c6c;
    border-top: none;
    box-shadow: 0px 0px 4px 1px rgba($black, 0.11);
    position: relative;
    font-size: rem(16px);
    padding-left: rem(20px);

    &::after {
        content: "";
        position: absolute;
        left: 0;
        height: 100%;
        width: rem(6px);
        background-color: $success;
        top: 0;
    }

    a.button {
        color: $black;
        background-color: transparent;
    }
}

.woocommerce .cart-collaterals .cart_totals,
.woocommerce-page .cart-collaterals .cart_totals {
    width: 100%;
    float: unset;

    .woocommerce-shipping-totals.shipping {
        display: none !important;
    }
}

.woocommerce-page table.shop_table .product-name {
    font-size: rem(16px);
    font-weight: bold;
}

.wsatc-stick-cart-wrapper .wsatc-add-to-cart {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    min-width: rem(154px);
    padding: .688rem 1rem;
    padding-top: .688rem !important;
    padding-bottom: .688rem !important;
    font-size: rem(16px);
    line-height: rem(24px);
    border-radius: 100px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    color: $white;
    background-color: $info;
    border-color: $info;
    min-width: rem(220px);
    margin-left: rem(7px);

    &:hover {
        background-color: $warning;
        border-color: $warning;
        color: $white;
    }
}

.wsatc-stick-cart-wrapper .product-title {
    font-weight: 500;
    color: $primary;
    font-size: rem(16px);
}

.wsatc-qty-wrapper {
    border-radius: rem(60px);
    overflow: hidden;
    align-items: center;
    display: flex;
    background-color: $white;
    border: 1px solid $gray-d8d;
}



.wsatc-right-section {
    .wsatc-qty-wrapper .wsatc-qty-field {
        width: rem(50px);
        border: none;
        background-color: transparent;
        font-size: rem(18px);
    }

    .wsatc-qty-wrapper .wsatc-qty-minus,
    .wsatc-qty-wrapper .wsatc-qty-plus {
        height: rem(20px);
        width: rem(20px);
        padding: 0;
        background-color: transparent;
        border-radius: 100% !important;
        font-size: rem(20px);
        margin: 0 rem(7px);
        transition-duration: .3s;

        &:hover {
            background-color: $info;
            color: $white;
        }
    }
}

.shop_table {
    th {
        font-weight: 400;
        color: $gray-6c6;
        font-size: rem(16px);
        flex: 0 0 30%;
    }

    td {
        font-size: rem(16px);
        flex: 0 0 70%;
        padding-left: rem(20px);

        @media #{$max-md} {
            padding-left: rem(0px);
            font-size: rem(22px);
        }

        @media #{$max-sm} {
            font-size: rem(14px);
        }

    }

    tr {
        margin-bottom: rem(16px);
    }

    .product-quantity {
        @media #{$max-md} {
            display: flex !important;
            justify-content: space-between;
        }

        .quantity {
            max-width: rem(200px);
        }
    }

    .product-remove {
        @media #{$max-md} {
            position: absolute;
            top: 0;
            right: rem(40px);
            top: rem(40px);
            border-top: none;
        }

        @media #{$max-sm} {
            right: rem(24px);
            top: rem(24px);
        }

        a {

            @media #{$max-sm} {
                font-size: rem(22px);
                width: rem(32px);
                height: rem(32px);
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: rem(32px);
                font-weight: 400;
            }

            @media #{$max-sm} {
                right: rem(24px);
                top: rem(24px);
            }
        }
    }
}

.wc-proceed-to-checkout {
    margin-top: rem(16px);
}

.woocommerce-shipping-totals {

    td {
        flex-direction: column;
    }
}

.woocommerce .cart-collaterals .cart_totals,
.woocommerce-page .cart-collaterals .cart_totals {
    border: 1px solid $gray-d8d;
    padding: rem(24px);

    tr {
        @media #{$max-md} {
            border: none !important
        }
    }

    td {
        @media #{$max-md} {
            padding-left: 0 !important;
        }
    }
}

.woocommerce .cart-collaterals h2 {
    color: $black;
    font-size: rem(24px);
    font-weight: 700;
    margin-bottom: rem(32px);
}

.shipping-calculator-button {
    text-decoration: underline;
}

.order-total {
    margin-top: rem(16px);
    align-items: center;

    .woocommerce-Price-amount {
        font-size: rem(24px);
        color: $primary;

    }
}

.woocommerce .woocommerce-cart-form button[name=apply_coupon],
.woocommerce .woocommerce-cart-form button[name=update_cart] {

    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    min-width: rem(154px);
    padding: .688rem 1rem;
    padding-top: .688rem !important;
    padding-bottom: .688rem !important;
    font-size: rem(16px);
    line-height: rem(24px);
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    color: $white;
    background-color: $warning;
    border-color: $warning;

    &:hover {
        color: $white;
        background-color: darken($warning, 4%);
    }

    &:disabled {
        background-color: $gray-400;
        cursor: not-allowed;
        border-color: $gray-400;
        ;

        &:hover {
            background-color: $gray-400;
        }
    }
}

label {
    &.error {
        font-size: 10px;
        color: $red;
    }
}

.woocommerce .woocommerce-cart-form button[name=update_cart] {
    background-color: $blue-2;
    border-color: $blue-2;
    cursor: pointer;
    border-radius: rem(100px);

    &:hover {
        color: $white;
        background-color: darken($blue-2, 4%);
    }
}

.woocommerce .cart-collaterals,
.woocommerce-page .cart-collaterals {
    width: 100%;
    margin-bottom: rem(64px);

    @media #{$max-md} {
        margin-top: rem(32px);
    }
}

.woocommerce .woocommerce-cart-form .shop_table_responsive {}

.woocommerce table.shop_table_responsive tr:first-child,
.woocommerce-page table.shop_table_responsive tr:first-child {
    @media #{$max-md} {
        border-top: 1px solid $gray-d8d;
    }
}

.woocommerce table.shop_table tbody tr,
.woocommerce-page table.shop_table tbody tr {
    @media #{$max-md} {
        // border: 1px solid $gray-d8d;
        position: relative;
    }

    td {

        @media #{$max-md} {
            padding-left: rem(20px);
        }
    }
}

.woocommerce-billing-fields__field-wrapper,
.woocommerce-additional-fields__field-wrapper,
.woocommerce-shipping-fields__field-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 0 rem(-15px);

    .form-row {
        flex: 0 0 50%;
        min-width: 50%;
        padding: 0 rem(15px);
        display: flex;

        &.notes {
            flex: 0 0 100%
        }

        &>label {
            flex: 0 0 100%;
        }

        &>span {

            flex: 0 0 100%;
        }
    }
}

.woocommerce-checkout form #order_review,
.woocommerce-checkout form #order_review_heading {
    width: 100%;
    margin-top: 0;
}

.woocommerce-checkout form #order_review .woocommerce-checkout-review-order-table {
    margin-top: 0;
    border: 1px solid $gray-d8d;
    width: 100%;
}

.woocommerce-checkout form #order_review .woocommerce-checkout-review-order-table td,
.woocommerce-checkout form #order_review .woocommerce-checkout-review-order-table th,
.woocommerce-checkout form #order_review_heading .woocommerce-checkout-review-order-table td,
.woocommerce-checkout form #order_review_heading .woocommerce-checkout-review-order-table th {
    border: 0;
    padding: rem(16px);
}

.wc_payment_method input[type=radio],
.woocommerce-checkout-review-order-table input[type=radio].shipping_method {
    display: inline-block !important;
}

.wc_payment_method input[type=radio]+label::before,
.woocommerce-checkout-review-order-table input[type=radio].shipping_method+label::before {
    content: none;
}

.woocommerce-terms-and-conditions-wrapper .woocommerce-privacy-policy-text p {
    font-size: rem(16px);
}

textarea {
    min-height: rem(160px);
}

.woocommerce-checkout form[name=checkout] {
    display: table;
    margin-bottom: rem(120px);
}

.woocommerce-products-header {
    padding: 0;
}

.related.products ul.products li.product,
.up-sells ul.products li.product {
    height: unset;
}

.woocommerce ul.products.columns-4 li.product {

    flex: 0 0 25%;
    min-width: 25%;
    margin: 0 0px 16px 0px;
    padding: 0 8px;

    @media #{$max-md} {
        flex: 0 0 50%;
        min-width: 50%
    }

    @media #{$max-sm} {
        flex: 0 0 100%;
        min-width: 100%
    }
}

.woocommerce ul.products.columns-4.swiper-container li.product {

    flex: 0 0 100%;
    min-width: 100%;
    margin: 0 0px 16px 0px;
    padding: 0 8px;
    height: 100%;

    @media #{$max-md} {
        flex: 0 0 100%;
        min-width: 100%
    }

    @media #{$max-sm} {
        flex: 0 0 100%;
        min-width: 100%
    }
}

.table-striped tbody tr:nth-of-type(even) {
    @media #{$max-md} {
        background-color: rgba(0, 0, 0, 0.05);
    }
}

.bapf_head{
   position: relative;
}

/*Loader Css*/
.loader-ajax {
    background-color: $white;
    bottom: 0;
    height: 50px;
    left: 0;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: 9999;
    border-radius: 5px;
}

.lds-ellipsis {
    margin: 0 auto;
    position: relative;
    top: 50%;
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 64px;
    text-align: center;
    z-index: 9999;
}

.loader-ajax .lds-ellipsis span {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: $info;
    -webkit-animation: ball-pulse-sync 1s 0s infinite ease-in-out;
    animation: ball-pulse-sync 1s 0s infinite ease-in-out;
}

.lds-ellipsis span:nth-child(1) {
    -webkit-animation: ball-pulse-sync 1s -.14s infinite ease-in-out;
    animation: ball-pulse-sync 1s -.14s infinite ease-in-out
}

.lds-ellipsis span:nth-child(2) {
    -webkit-animation: ball-pulse-sync 1s -70ms infinite ease-in-out;
    animation: ball-pulse-sync 1s -70ms infinite ease-in-out
}

@-webkit-keyframes ball-pulse-sync {
    33% {
        -webkit-transform: translateY(10px);
        transform: translateY(10px)
    }

    66% {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px)
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

@keyframes ball-pulse-sync {
    33% {
        -webkit-transform: translateY(10px);
        transform: translateY(10px)
    }

    66% {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px)
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}