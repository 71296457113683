.s-alaune {
    padding-top: rem(50px);
    &-row {
        margin-left: rem(-8px);
        margin-right: rem(-8px);
    }
    &-title {
        font-size: rem(38px);
        line-height: rem(47px);
        font-weight: bold;
        color: $primary;
        margin-bottom: rem(32px);
        @media #{$max-sm} {
            font-size: rem(28px);
            line-height: rem(32px);
        }
    }
}