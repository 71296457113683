.card-why {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: rem(226px);

    @media #{$max-xl} {
        max-width: rem(166px);
    }

    &-box {

        @media #{$max-md} {
            margin-bottom: rem(64px);

            &:last-child {

                margin-bottom: rem(5px);
            }
        }
    }

    &-img {
        height: rem(160px);
        width: rem(160px);
        border-radius: 50%;
        box-shadow: 0 0 rem(20px) rem(-2px) rgba($black, 0.14);
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: rem(32px);
        padding: rem(40px);

        img {
            // height: 100%;
            width: 100%;
        }

        @media #{$max-xl} {
            height: rem(100px);
            width: rem(100px);
            margin-bottom: rem(26px);
            padding: rem(24px);
        }

        @media #{$max-sm} {
            height: rem(100px);
            width: rem(100px);
            margin-bottom: rem(35px);
        }
    }

    &-title {
        text-align: center;
        font-size: 1.3rem;
        line-height: rem(29px);
        margin-bottom: rem(8px);
        color: $primary;
        font-weight: bold;

        @media #{$max-md} {
            font-size: rem(20px);
            line-height: rem(24px);
        }
    }

    &-disc {
        text-align: center;
        font-size: rem(16px);
        line-height: rem(19px);
        color: $gray-8a8;

        @media #{$max-md} {
            font-size: rem(14px);
            line-height: rem(17px);
        }
    }
}