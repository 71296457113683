.card-testimonial {
    background-color: $white;
    padding: rem(60px) rem(32px) rem(16px);
    position: relative;
    margin-top: rem(42px);
    margin-bottom: rem(42px);
    opacity: 0.5;
    transition-duration: 0.5s;
    box-shadow: none;

    @media #{$max-md} {
        margin-top: rem(40px);
        margin-bottom: rem(40px);
    }

    @media #{$max-sm} {
        padding: rem(40px) rem(24px) rem(80px);
    }

    &::before {
        content: "";
        height: rem(80px);
        width: rem(80px);
        border-radius: 100%;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        background-color: $info;
        background-image: url(../images/svg/testimonial-icon.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: rem(42px) rem(32px);


        @media #{$max-md} {
            height: rem(80px);
            width: rem(80px);
            background-size: 50%;
        }

        @media #{$max-sm} {
            height: rem(60px);
            width: rem(60px);
            background-size: 45%;
        }

    }

    &-content {
        display: flex;
        justify-content: center;
        text-align: center;
        font-size: rem(22px);
        color: $black;
        font-weight: 500;
        margin-bottom: rem(28px);

        @media #{$max-md} {
            font-size: rem(24px);
        }

        @media #{$max-sm} {
            margin-bottom: rem(20px);
            font-size: rem(20px) !important;
        }

    }

    &-info {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            height: rem(48px);
            width: rem(48px);
            border-radius: rem(50px);
            margin-right: rem(8px);
        }
    }

    &-name {}
}

.swiper-slide-active {
    .card-testimonial {
        opacity: 1;
        box-shadow: rem(0px) rem(16px) rem(32px) rgba($black, 0.078);
    }
}