.card-tm {
    padding: rem(70px) 0;

    @media #{$max-xl} {
        padding: rem(50px) 0;
    }

    &-body {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        &.image-text,
        &.video-text {
            flex-direction: row-reverse;

            .card-tm {
                &-text {
                    padding: rem(32px) rem(32px) rem(32px) rem(64px);
                }
            }
        }
    }

    &-text {
        flex: 0 0 34%;
        min-width: 34%;
        padding: rem(40px) rem(64px) rem(32px) rem(0px);

        @media #{$max-md} {
            flex: 0 0 100%;
            min-width: 100%;
            order: 2;
        }

        @media #{$max-md} {
            padding: rem(10px) rem(2px) rem(32px) rem(0px)
        }

        p {
            color: $gray-6c6;
            line-height: rem(24px);
        }

        ul {
            color: $primary;
            font-weight: 600;
            list-style: none;
            padding: 0;
            margin: rem(32px) 0 rem(24px);

            @media #{$max-md} {

                margin: rem(20px) 0;
            }
        }

        li {
            line-height: rem(24px);
            margin-bottom: rem(18px);
            position: relative;
            padding-left: rem(34px);

            &::after {
                position: absolute;
                left: 0;
                content: "\f058";
                top: rem(1px);
                font-family: 'Font Awesome 5 Free';
                font-weight: 900;
                color: $warning;
                font-size: rem(14px);
            }
        }
    }

    &-media {
        flex: 0 0 66%;
        min-width: 66%;
        background-color: $primary;
        height: 27rem;
        border-radius: rem(16px);
        overflow: hidden;

        @media #{$max-md} {
            flex: 0 0 100%;
            min-width: 100%;
            order: 1;
            height: rem(420px);
        }

        @media #{$max-sm} {
            height: rem(200px);
        }

        &>img {

            border-radius: rem(16px);
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &-cta {
        margin-top: rem(10px);
    }

    &-title {
        font-weight: bold;
        color: $primary;
        margin-bottom: rem(16px);
        letter-spacing: rem(1.2px);


        @media #{$max-sm} {

            font-size: rem(28px);
            line-height: rem(32px);
        }

        strong {
            color: $warning;
            font-weight: bold;
        }
    }
}



.video-block {
    height: 100%;
    position: relative;


    &-img {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-color: rgba($black, 0.4);
            z-index: 1;
        }
    }

    &-play {
        height: rem(146px);
        width: rem(146px);
        background-color: transparent;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        z-index: 10;
        border: rem(12px) solid $warning;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $light;
        font-size: rem(54px);
        line-height: 1;
        padding-top: rem(4px);

        &:hover {
            transform: translateX(-50%) translateY(-50%) scale(1.1);
        }

        @media #{$max-sm} {
            height: rem(52px);
            width: rem(52px);
            border-radius: 100%;
            border-width: rem(2px);
            font-size: rem(20px);
        }
    }

    h2 {
        margin: 0;
        position: absolute;
        bottom: rem(32px);
        left: 0;
        z-index: 10;
        font-size: rem(30px);
        line-height: rem(37px);
        padding: rem(15px) rem(24px) rem(10px) rem(73px);
        // background-color: $primary;
        color: $white;
        text-transform: none;

        @media #{$max-md} {
            font-size: rem(24px);
            line-height: rem(26px);
            padding: rem(12px) rem(24px) rem(8px) rem(24px);
            bottom: rem(40px);
        }

        @media #{$max-sm} {
            font-size: rem(14px);
            line-height: rem(22px);
            padding: rem(8px) rem(24px) rem(8px) rem(16px);
            bottom: rem(20px);
        }
    }

    &-player {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 13;
        opacity: 0;
        pointer-events: none;
        transition-duration: .3s;

        &>div {
            height: 100%;
            width: 100%;
        }

        &.show {
            opacity: 1;
            pointer-events: all;
        }

        iframe {
            height: 100%;
            width: 100%;
        }

        .plyr {

            height: 100%;
            width: 100%;
        }
    }

    iframe {
        height: 100%;
        width: 100%;
    }

}

.plyr--full-ui.plyr--video .plyr__control--overlaid {
    height: rem(60px);
    width: rem(85px);
    background-color: rgba($light, 0.8);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 10;
    border: none;
    border-radius: rem(20px);
    display: flex;
    justify-content: center;
    align-items: center;
    color: $light;
    font-size: rem(28px);
    line-height: 1;
    padding: 0;

    &:hover {
        transform: translateX(-50%) translateY(-50%) scale(1.1);
    }

    @media #{$max-sm} {
        height: rem(50px);
        width: rem(74px);
        border-radius: rem(16px);
    }

    svg {
        --plyr-control-icon-size: rem(20px);
        --swiper-theme-color: $white;
        --plyr-color-main: $white;
        color: $white;
    }
}

:root {
    --swiper-theme-color: #{$primary};
    --plyr-video-control-background-hover: #{$primary};
    --plyr-color-main: #{$primary};
    --plyr-control-icon-size: rem(12px);
    --global--color-primary: #{$primary};
}

.plyr--full-ui button {
    border-radius: 100%;
    background-color: $white;
    height: rem(40px);
    width: rem(40px);
    display: flex !important;
    align-items: center;
    justify-content: center;

    @media #{$max-sm} {

        height: rem(20px);
        width: rem(20px);
    }

    svg {
        color: $white;
    }

    &:hover {
        background: $primary;

        svg {
            color: $white;
        }
    }
}

.plyr--video .plyr__control {
    background-color: $primary;

    &.plyr__tab-focus,
    &:hover,
    &[aria-expanded=true] {
        background-color: $white;

        svg {
            color: $primary;
        }

    }
}