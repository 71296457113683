.bapf_button,
.bapf_reset {
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    min-width: rem(154px);
    padding: $btn-padding-y $btn-padding-x;
    font-size: rem(16px);
    line-height: rem(24px);
    border-radius: rem(100px);
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    color: $white;
    background-color: $primary;
    border-color: $primary;
    width: 100%;
    display: block;

}


.bapf_sfilter .bapf_update {

    // margin-top: rem(100px);
    margin-bottom: rem(-20px);
    transition-duration: 0.15s;

    &:hover {
        background-color: $info;
        border-color: $info;
    }
}

.bapf_reset {
    background-color: transparent;
    color: $black;
    text-decoration: underline;
    border: none;
}

.bapf_slct {
    .bapf_head {
        color: $white;
        display: flex;
        align-items: center;
        position: relative;
        z-index: 1000;

        &:hover {
            z-index: 1001;
        }

        .filter-tooltip {
            margin-left: rem(4px);
        }

        h3 {
            font-size: rem(16px);
            margin-bottom: rem(3px);

            @media (max-width:767.98px) {
                font-size: rem(13px);
                margin-bottom: rem(2px);
            }
        }
    }

    .select2-container .select2-selection--single .select2-selection__rendered {

        @media (max-width:767.98px) {
            height: rem(36px);
            min-height: rem(36px);
        }
    }
}

.bapf_sfilter {
    .bapf_head {
        h3 {
            @media (max-width:767.98px) {
                font-size: rem(16px);
                margin-bottom: rem(3px);
            }
        }
    }

    .bapf_val {
        @media (max-width:767.98px) {
            font-size: rem(14px);
        }
    }
}