.card-help {
    display: flex;
    flex-wrap: wrap;
    border-radius: rem(6px);
    padding: rem(24px) rem(48px) rem(24px) rem(24px);
    color: $white;
    background-image: url("../images/svg/support.svg"), linear-gradient(273.03deg, $cyan 2.25%, $blue-2 97.48%);
    background-position: rem(-5px) rem(14px), 0 0;
    background-repeat: no-repeat;
    position: relative;
    z-index: 2;

    &-box {
        position: relative;
        padding-top: rem(24px);
        padding-bottom: rem(24px);

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: -50vw;
            width: 200vw;
            height: 100%;
            background-color: $gray-f6f;
        }
    }

    @media #{$max-sm} {
        padding: rem(16px);
        background-size: 100% 107%, cover;
        background-position: rem(-143px) rem(-16px), 0 0;
    }


    &-body {
        flex: 0 0 66.66%;
        min-width: 66.66%;
        padding-right: rem(20px);

        @media #{$max-sm} {
            flex: 0 0 100%;
            min-width: 100%;
            margin-bottom: rem(32px);
            padding-right: 0;
        }
    }

    &-title {
        display: block;
        font-size: rem(32px);
        line-height: 1.21;
        font-weight: bold;

        @media #{$max-sm} {
            font-size: rem(28px);
            margin-bottom: rem(8px);
        }
    }

    &-disc {
        font-size: rem(18px);
        line-height: 1.55;
        font-weight: 500;

        @media #{$max-sm} {}
    }

    &-cta {
        flex: 0 0 33.33%;
        min-width: 33.33%;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        @media #{$max-sm} {
            flex: 0 0 100%;
            min-width: 100%;
            justify-content: flex-start;
        }
    }
}