.s-testimonial {
    background-color: $gray-f6f;
    padding-top: rem(32px);
    padding-bottom: rem(16px);
    margin-top: rem(32px);

    &-title {
        font-size: rem(28px);
        line-height: 1.218;
        text-align: center;

        @media #{$max-sm} {
            font-size: rem(26px);
        }
    }

    .swiper-pagination-bullet {
        height: rem(16px);
        width: rem(16px);
        opacity: 1;
        background-color: transparent;
        border: 4px solid $primary;
        transition-duration: .5s;

        @media #{$max-sm} {}
    }

    .swiper-pagination-bullet-active {
        background-color: $info;
        border-color: $info;
    }

    .swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
        margin: 0 rem(6.5px);
    }


    .swiper-container {
        padding-bottom: rem(38px);

        @media #{$max-sm} {
            padding: 0;
        }
    }

    .swiper-button-next,
    .swiper-button-prev {
        background-color: $warning;
        transform: translateY(-70%);

        &:after {
            font-size: rem(14px);
        }

    }

    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
        right: calc((100vw - #{rem(1466px)}) / 2);
    }

    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
        left: calc((100vw - #{rem(1466px)}) / 2);
    }

    .swiper-container-horizontal>.swiper-pagination-bullets,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {
        @media #{$max-sm} {
            bottom: rem(63px);
        }
    }
}