.page-404 {

    padding-top: rem(64px);
    padding-bottom: rem(120px);

    @media #{$max-sm} {
        padding-top: rem(32px);
        padding-bottom: rem(64px);
    }

    &-img {
        font-size: rem(200px);
        font-weight: bold;
        display: flex;
        align-items: center;
        color: $primary;

        @media #{$max-md} {
            font-size: rem(160px);
        }

        @media #{$max-sm} {
            font-size: rem(100px);
        }

        img {
            width: rem(200px);

            @media #{$max-md} {
                width: rem(160px);
            }

            @media #{$max-sm} {
                width: rem(100px);
            }
        }
    }

    h1 {
        margin-bottom: rem(71px);
        margin-left: rem(34px);
        color: $primary;

        @media #{$max-md} {
            margin-bottom: rem(48px);
            margin-left: 0;
        }
    }

    h2 {
        color: $warning;

        @media #{$max-md} {
            text-align: center;
            margin-bottom: rem(32px);
        }
        @media #{$max-sm} {
            font-size: rem(24px);
        }
    }
}