.contact-form {
    margin: rem(64px) 0 rem(120px) 0;
    padding: rem(64px) rem(64px) rem(39px);
    background-color: $white;
    border: 1px solid $gray-d8d;

    @media #{$max-md} {
        margin: rem(32px) 0 rem(60px) 0;
        padding: rem(32px) rem(32px) rem(20px);
    }

    textarea {
        height: rem(200px);

        &:focus {
            background-color: $white;
        }
    }

    &-col {
        padding: 0 rem(32px);

        @media #{$max-md} {
            padding: 0 rem(15px);
        }

    }

    .form-control {
        border-radius: 0;
        background-color: $gray-f3f;
    }

    label {
        font-size: rem(14px);
        margin-bottom: 2px;
    }

    &-iframe {
        height: rem(260px);
        width: 100%;
        margin-top: rem(34px);
    }

    &-ul {
        list-style: none;
        padding: 0;

        li {
            margin-bottom: rem(16px);
            @media #{$max-sm} {
                font-size: rem(8px);
            }


            &:last-child {
                margin-bottom: 0;
            }

            a {
                font-size: rem(18px);
                color: $blue-2;
                display: flex;
                align-items: center;

                @media #{$max-sm} {
                    font-size: rem(14px);
                }

                &:hover {
                    color: $warning;

                    i {
                        border-color: $warning;
                    }
                }

                i {
                    display: inline-flex;
                    height: rem(50px);
                    width: rem(50px);
                    border: rem(2px) solid $blue-2;
                    border-radius: 50%;
                    align-items: center;
                    justify-content: center;
                    margin-right: rem(16px);
                    transition-duration: .15s;

                    @media #{$max-sm} {
                        height: rem(40px);
                        width: rem(40px);
                        margin-right: rem(8px);
                    }
                }
            }
        }
    }

    &-cta {
        margin-top: rem(24px);
        @media #{$max-sm} {
            margin-top: rem(12px);
        }

        .btn {
            display: block;
            width: 100%;
        }
    }

    &-bg {
        background-image: url(../images/bg-article.png);
        background-repeat: no-repeat;
        background-size: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: -1;
        height: 100vh;
        width: 100vw;
    }
}