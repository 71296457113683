.s-nosgaranties {
    background-color: $primary;
    padding: rem(32px) 0;

    &-title {
        color: $white;
        font-size: rem(30px);
        line-height: 1.218;
        text-align: center;
    }
}