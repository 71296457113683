.card {
    border-radius: rem(8px);
    height: 100%;

    &-title,
    h2 {
        font-size: 1.3rem;
        line-height: 1.208;
        margin-bottom: 0.1rem;

        @media #{$max-sm} {
            font-size: rem(20px);
            margin-bottom: rem(14px);
        }
    }

    &-text {
        font-size: 1.025rem;
        line-height: 1.555;
        color: $gray-6c6;

        @media #{$max-sm} {
            font-size: rem(16px);
            line-height: 1.4;
        }
    }

    ul {
        padding: 0;
        margin: 0;
        list-style: none;
        margin-top: rem(2px);

        li {
            padding-left: rem(32px);
            font-size: rem(16px);
            font-weight: 600;
            line-height: 1.5;
            color: $primary;
            position: relative;
            margin-bottom: 0.25rem;

            @media #{$max-sm} {
                font-size: rem(14px);
                margin-bottom: 0.25rem;
                padding-left: rem(20px);
            }

            &:last-child {
                margin-bottom: 0;
            }

            &::after {
                content: "\f058";
                position: absolute;
                font-family: 'Font Awesome 5 Free';
                font-weight: 900;
                line-height: 1;
                top: rem(5px);
                left: rem(8px);
                color: $warning;
                font-size: rem(14px);

                @media #{$max-sm} {
                    top: rem(5px);
                    left: rem(0px);
                    font-size: rem(12px);
                }
            }
        }
    }

    &-img-top,
    img {
        height: rem(256px) !important;
        width: 100% !important;
        max-width: unset !important;
        object-fit: cover;
        border-top-left-radius: rem(8px);
        border-top-right-radius: rem(8px);

        @media #{$max-md} {
            height: rem(260px) !important;
            width: 100% !important;
            max-width: unset !important;
        }

        @media #{$max-sm} {
            height: rem(220px) !important;
            width: 100% !important;
            max-width: unset !important;
        }
    }

    &-body {
        padding: 1.2rem;
        padding-bottom: 0;

        @media #{$max-sm} {
            padding: rem(20px);
        }

        &~.card-footer {
            padding-top: 0;
        }
    }

    &-footer {
        background-color: transparent;
        border-top: none;
        padding: rem(32px);
        padding-bottom: 1.5rem;

        @media #{$max-sm} {
            padding: rem(20px);
        }
    }

    .card-tm-cta {
        display: flex;
        align-items: flex-start;
        margin: 0 rem(-5px);

        .btn {
            font-size: rem(13px);
            margin: 0 rem(5px);
            padding: rem(8px) rem(8px);
            flex: 1;
        }
    }

}