.card-alaune {
    border-radius: rem(8px);
    position: relative;
    overflow: hidden;
    padding: rem(24px);
    color: $white;
    min-height: rem(340px);
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    background-size: cover;
    height: 100%;

    @media #{$max-xl} {
        min-height: rem(300px);
        padding: rem(20px);
    }
    @media #{$max-md} {
        min-height: rem(318px);
        padding: rem(20px);
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(91.26deg, rgba($black, 0.702) 0.94%, rgba($black, 0) 98.84%);
        z-index: 1;
    }

    &-bg {
        height: 100%;
        width: 100%;
        object-fit: cover;
        position: absolute;
        left: 0;
        top: 0;
    }


    &-info {
        position: relative;
        z-index: 2;
        flex: 0 0 100%;
    }

    &-footer {
        position: relative;
        z-index: 2;
        flex: 0 0 100%;
    }

    &-name {
        margin-bottom: rem(16px);
        display: flex;
        flex-direction: column;
        max-width: rem(312px);
    }

    &-title {
        font-size: rem(36px);
        font-weight: bold;
        line-height: rem(44px);
        margin-bottom: rem(8px);
    }

    &-stitle {
        font-size: rem(18px);
        line-height: rem(22px);
        display: block;
        max-width: rem(190px);
    }

    &-sizes {
        color: $warning;
        font-size: rem(18px);
        line-height: rem(22px);
        font-weight: bold;
    }

    &-nbr {
        display: block;
        margin-bottom: rem(13px);
    }

    &-wrapper {
        flex: 0 0 25%;
        min-width: 25%;
        margin-bottom: rem(16px);
        padding: 0 rem(8px);

        @media #{$max-md} {
            flex: 0 0 50%;
            min-width: 50%;
        }
        @media #{$max-sm} {
            flex: 0 0 100%;
            min-width: 100%;
        }

        &:nth-child(3) {
            flex: 0 0 50%;
            min-width: 50%;

            @media #{$max-sm} {
                flex: 0 0 100%;
                min-width: 100%;
            }
            .card-alaune {
                &-name {}

                &-title {
                    font-size: rem(48px);
                    margin-top: rem(5px);
                    margin-bottom: rem(16px);
                }

                &-stitle {
                    max-width: rem(300px);
                }
            }

        }

        &:nth-child(4),
        &:nth-child(5) {
            flex: 0 0 50%;
            min-width: 50%;

            @media #{$max-sm} {
                flex: 0 0 100%;
                min-width: 100%;
            }

            .card-alaune {

                min-height: unset;

                &-name {
                    flex-direction: column-reverse;
                    max-width: rem(225px);
                    margin-bottom: 0;
                }

                &-stitle {
                    max-width: rem(210px);
                }

            }
        }

        &:nth-child(5) {

            @media #{$max-md} {
                flex: 0 0 100%;
                min-width: 100%;
            }

            .card-alaune {
                &-title {
                    margin-bottom: rem(0px);
                }

                &-stitle {
                    margin-bottom: rem(6px);
                }
            }
        }
    }
}