.joaccordion {
    margin-bottom: rem(16px);
    border: 1px solid $gray-f3f;
    box-shadow: 0 0 9px -2px rgba($black, 0.22);

    &-header {
        background: $gray-f3f;
        color: $primary;
        padding: rem(16px) rem(18px);
        font-size: rem(24px);
        font-weight: 600;
        position: relative;

        &::after {
            content: "\f078";
            font-family: 'Font Awesome 5 Free';
            font-weight: 900;
            position: absolute;
            top: 50%;
            right: rem(20px);
            transform: translateY(-50%) scaleY(-1);
            color: $white;
            transition-duration: .3s;
        }

        &.collapsed {
            &::after {
                transform: translateY(-50%) scaleY(1);
            }
        }
    }

    &-body {
        padding: rem(16px) rem(20px);
        font-size: rem(20px);
        line-height: 2;
        color: $gray-900;
    }
}