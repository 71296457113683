.card-nosgaranties {
    background-color: $blue-2;
    border-radius: rem(8px);
    overflow: hidden;
    height: 100%;

    &-title {
        color: $white;
        font-size: rem(24px);
        text-align: center;
        line-height: 1.333;
    }

    &-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: rem(22px) rem(24px) rem(32px);
        background-size: rem(217px) rem(184px);
        background-position: rem(-32px) rem(47px);
        background-repeat: no-repeat;

        img {
            height: rem(64px);
            margin-bottom: rem(32px);
        }
    }
}