body.woocommerce-checkout {
    background-color: $gray-f6f;

    .header {
        max-width: 992px;
        margin: 0 auto;
        box-shadow: none;
        margin-top: rem(24px);

        @media #{$max-sm} {
            max-width: calc(100% - #{rem(30px)});
            padding-left: 0;
        }
    }

    .menu .btn-call {
        display: flex;
    }

    .btn-toggle-menu {
        display: none;
    }

    .menu-items-right li {
        display: flex;
        align-items: center;

        p {
            margin-bottom: 0;
            font-size: rem(14px);
            font-weight: 500;
            color: $primary;
            margin-right: rem(24px);

            @media #{$max-sm} {
                font-size: 0;
            }
        }
    }

    h2,
    h3 {
        font-size: rem(22px);
        margin-top: rem(16px) !important;
        font-weight: bold;

        @media #{$max-sm} {
            font-size: rem(18px);
        }
    }

    #wpmc-next {
        opacity: 0;
        position: absolute;
        pointer-events: none;

        &:not(.current) {
            &~#checkout-next {
                display: none;
            }
        }
    }

    .select2-container .select2-selection--single .select2-selection__rendered {
        height: rem(48px);
        padding: .688rem 1rem;
        font-weight: 500;
        border: 1px solid $gray-400;
        border-radius: .25rem;
    }



    .select2-container--default .select2-selection--single .select2-selection__arrow b {
        top: rem(12px);
        right: rem(10px);
        left: unset;
    }

    form #order_review,
    form #order_review_heading {
        padding-left: 0;
    }

    table.shop_table {
        .product-name {
            color: $primary;

            @media #{$max-md} {
                font-size: rem(14px);
                padding-bottom: 8px !important;
            }
        }

        .product-total {
            color: $blue-2;
            font-size: rem(18px);
            flex: 0 0 rem(220px);

            @media #{$max-md} {
                flex: 0 0 100%;
                padding-top: 0 !important;
                font-size: rem(14px);
            }
        }

        .order-total {
            background-color: $warning;
            margin: 0 rem(-30px);

            .woocommerce-Price-amount {
                color: $white;
            }

            th {
                flex: 1;
                color: $white;
                font-weight: bold;
            }

            td {

                flex: 0 0 rem(320px);
                color: $white;
                display: flex;
                justify-content: center;
            }
        }
    }

    .cart-subtotal {
        th {
            flex: 1;
            font-weight: bold;

            @media #{$max-sm} {
                font-size: rem(14px);
                padding-left: 0 !important;
            }
        }

        td {
            flex: 0 0 rem(320px);
            display: flex;
            justify-content: center;
            font-size: rem(18px);
            color: $blue-2;

            @media #{$max-sm} {
                font-size: rem(14px);
                flex: 1;
                padding-right: 0 !important;
            }
        }
    }

    .product-miniature {
        flex: 0 0 rem(80px);

        @media #{$max-md} {
            flex: 0 0 50px;
            padding: 5px !important;
        }
    }

    .product-name {
        flex: 1;

        // @media #{$max-md} {
        //     flex: 0 0 100%;
        // }
    }



    .payment_methods {
        padding-left: 0;
    }

    .woocommerce-checkout-payment {

        .payment_methods {
            display: flex;
            margin: 0 rem(-15px);

            @media #{$max-sm} {
                flex-wrap: wrap;
            }

            .wc_payment_method {
                margin-bottom: rem(20px);
                flex: 0 0 50%;
                min-width: 50%;
                padding: 0 rem(20px);

                @media #{$max-sm} {
                    flex: 0 0 100%;
                    min-width: 100%;
                }
            }

            .form-check-label {
                color: $black;
                font-size: rem(22px);
                position: relative;
                padding-left: rem(32px);
                margin-bottom: rem(10px);
                color: $blue-2;

                @media #{$max-sm} {
                    font-size: rem(18px);
                }

                &::after {
                    content: "";
                    position: absolute;
                    top: rem(4px);
                    left: 0;
                    width: rem(24px);
                    height: rem(24px);
                    border: 2px solid $warning;
                    border-radius: 50%;

                }

                &::before {
                    content: "";
                    position: absolute;
                    top: rem(10px);
                    left: rem(6px);
                    width: rem(12px);
                    height: rem(12px);
                    border: 2px solid $warning;
                    border-radius: 50%;
                    display: block;
                    background-color: $warning;
                    transition-duration: .15s;
                    margin: 0;
                    transform: scale(0);
                }

            }

            .form-check-input {
                opacity: 0;
                position: absolute;
                z-index: 0;

                &:checked {
                    &+.form-check-label {
                        &::before {
                            transform: scale(1);
                        }
                    }
                }
            }

            .form-check {
                padding-left: 0;
            }

            .payment_box {
                padding: rem(16px);
                background: $warning;
                color: $white;
                font-size: rem(18px);
                font-weight: 600;
                letter-spacing: rem(0.3px);
                text-align: justify;

                @media #{$max-sm} {
                    font-size: rem(14px);
                }
            }
        }

        .woocommerce-terms-and-conditions-wrapper .woocommerce-privacy-policy-text {
            background-color: $gray-f6f;
            padding: rem(12px) rem(18px);

            p {
                font-size: rem(16px);
                letter-spacing: rem(0.4px);

                a {
                    color: $warning;
                    text-decoration: underline;
                }
            }
        }

        .place-order {
            padding: 0 rem(10px);

            @media #{$max-sm} {
                padding: 0;
            }
        }

        .woocommerce-terms-and-conditions-wrapper {
            margin-top: rem(20px);
        }
    }

    .woocommerce form .form-row .input-text,
    .woocommerce-page form .form-row .input-text,
    .select2-container .select2-selection--single .select2-selection__rendered {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        width: 100%;
        border-radius: 0;
        background-color: #fcfcfc;
        border-color: #d2d2d2;
        height: 42px;
        font-size: 14px;
        letter-spacing: 0.4px;
        color: black;
        min-height: 42px;
        border-width: 2px;

        &.error {
            border-color: $red;
        }
    }

    select.error {
        &~.select2-container .select2-selection--single .select2-selection__rendered {
            border-color: $red;
        }
    }

    .woocommerce form .form-row textarea.input-text {
        height: 120px;
    }

    .select2-container .select2-selection--single {
        height: 42px;
    }

    .wpmc-nav-wrapper {

        @media #{$max-sm} {
            display: flex;
            flex-direction: column-reverse;
        }

        button {
            @media #{$max-sm} {
                margin-bottom: rem(16px);
            }
        }
    }

    .button {
        display: inline-block;
        font-weight: 500;
        text-align: center;
        vertical-align: middle;
        user-select: none;
        background-color: transparent;
        border: 1px solid transparent;
        min-width: rem(145px);
        text-transform: none;
        letter-spacing: 1px;
        padding: .6875rem 1.9375rem;
        font-size: 1rem;
        line-height: 1.5;
        border-radius: rem(100px);
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        color: $white;
        background-color: $info;

        &::first-letter {
            text-transform: uppercase;
        }

        &:disabled {
            opacity: .65;
        }

        &:hover {
            color: #212529;
            background-color: $warning;
            border-color: $warning;
            color: $white;
        }

        &:focus {
            color: #212529;
            background-color: $warning;
            border-color: $warning;
            box-shadow: 0 0 0 .2rem rgba(69, 192, 236, 0.5);
        }

        @media (max-width: 991.98px) {
            padding: rem(10px) rem(26px);
        }

        &#wpmc-back-to-cart {
            background-color: transparent;
            color: $warning;
            text-decoration: underline;
            padding-left: 0;
            padding-right: 0;
            font-weight: 400;

            &:hover {
                border-color: transparent;
            }
        }
    }
}

.woocommerce form .form-row label,
.woocommerce-page form .form-row label {
    display: block;
    font-size: 13px;
    margin-bottom: 2px;
    color: $gray-6c6;
    letter-spacing: 0.4px;
    font-weight: 600;

    &.error {
        position: absolute;
        bottom: rem(-18px);
        color: $red;
        font-size: rem(10px);
    }
}



.woocommerce-checkout .select2-container .select2-dropdown {
    border: 1px solid $gray-400;
}


.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: $warning;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
    border: 1px solid $gray-400;
    height: rem(42px);
    background-color: $gray-f6f;
}

.wpmc-tabs-wrapper {
    // margin-top: rem(52px);
    max-width: 992px;
    margin-left: auto;
    margin-right: auto;

    .wpmc-tabs-list {
        display: flex;
        background-color: transparent;

        @media #{$max-sm} {
            flex-wrap: wrap;
        }

        .wpmc-tab-item {
            flex: 1;
            position: relative;
            padding: 0;
            padding-left: rem(32px);
            background-color: $white;
            margin-right: rem(20px);
            display: flex;
            justify-content: center;
            color: $gray-6c6;

            @media #{$max-sm} {
                flex: 0 0 100%;
                // padding-left: 0;
                margin-bottom: rem(16px);

                &:last-child {
                    margin-bottom: 0;
                }
            }


            &.current {
                background-color: $warning;
                color: $white;
            }

            &::before {
                border-color: $white $white $white transparent;
                border-style: solid;
                border-width: rem(28px) rem(0px) rem(28px) rem(12px);
                content: "";
                left: rem(-12px);
                margin-top: rem(-28px);
                position: absolute;
                top: 50%;
                z-index: -1;

                @media #{$max-sm} {
                    display: none;
                }
            }

            &::after {
                content: "";
                border-bottom: rem(28px) solid transparent;
                border-left: rem(12px) solid $white;
                border-top: rem(28px) solid transparent;
                content: "";
                margin-top: rem(-28px);
                position: absolute;
                right: rem(-12px);
                top: 50%;

                @media #{$max-sm} {
                    display: none;
                }
            }

            &:last-child {
                margin-right: 0;

                &::after {
                    content: none;
                }
            }

            &:first-child {

                &::before {
                    content: none;
                }
            }

            &.current {
                &::before {
                    opacity: 1;
                    border-color: $warning $warning $warning transparent;
                }

                &::after {
                    border-left: rem(12px) solid $warning;
                }

                .wpmc-tab-text {

                    &::before {
                        color: $white;
                    }
                }
            }


            .wpmc-tab-number {
                position: absolute;
                left: rem(20px);
                top: 50%;
                transform: translateY(-50%);
                margin-top: 0;
                display: none;
            }

            .wpmc-tab-text {
                padding: rem(18px) rem(8px);
                font-size: rem(16px);
                font-weight: 500;
                padding-left: 0;
                display: inline-block;
                width: auto;
                position: relative;

                @media #{$max-md} {
                    font-size: rem(14px);
                }

                @media #{$max-sm} {
                    top: 0;
                }

                &::before {
                    font-family: 'Font Awesome 5 Free';
                    font-weight: bold;
                    position: absolute;
                    font-size: rem(22px);
                    top: 50%;
                    transform: translateY(-50%);
                    color: gray;

                    @media #{$max-md} {
                        font-size: rem(18px);
                    }

                }
            }
        }
    }



}

.wpmc-billing {
    .wpmc-tab-text {

        &::before {
            content: "\f007";
            font-weight: bold;
            left: rem(-30px);

            @media #{$max-md} {
                left: rem(-24px);
            }
        }
    }
}

.wpmc-review {
    .wpmc-tab-text {
        &::before {
            content: "\f0d1";
            font-weight: bold;
            left: rem(-40px);

            @media #{$max-md} {
                left: rem(-32px);
            }
        }
    }
}

.wpmc-payment {
    .wpmc-tab-text {
        &::before {
            content: "\f53d";
            font-weight: bold;
            left: rem(-40px);

            @media #{$max-md} {
                left: rem(-34px);
            }
        }
    }
}



.wpmc-nav-wrapper {
    max-width: 992px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 rem(15px);
    margin-bottom: rem(72px);

}

#ship-to-different-address label span {
    &::before {
        background-color: transparent;
        border: 2px solid $primary;
    }

    &::after {
        background-color: $primary;
    }

}

.woocommerce-checkout form #order_review .woocommerce-checkout-review-order-table {
    display: flex;
    flex-direction: column;
    border: none;

    tbody {
        display: flex;
        flex-direction: column;
    }

    tr {
        display: flex;
        align-items: center;

        @media #{$max-sm} {
            flex-wrap: wrap;
        }
    }

    .cart_item {
        // border: 1px solid #e9a124;
    }

    .woocommerce-shipping-totals {
        display: flex;
        flex-direction: column;

        @media #{$max-sm} {
            justify-content: flex-start;
        }

        th {
            width: 100%;
            padding-left: 0 !important;
            font-weight: bold;
        }

        td {
            padding: 0 !important;
        }
    }
}

.woocommerce-shipping-methods {
    display: flex;
    padding: 0;
    margin: 0 rem(-16px);
    flex-wrap: wrap;

    li {
        flex: 0 0 33.33%;
        min-width: 33.33%;
        padding: 0 rem(16px);

        @media #{$max-md} {
            flex: 0 0 100%;
            min-width: 100%;
            margin-bottom: rem(24px);
        }

        .form-check {
            height: 100%;
            padding: 0;

            .sh-icon {
                position: absolute;
                color: rgba($white, 0.212);
                top: rem(13px);
                z-index: 4;
                left: rem(18px);
                font-size: rem(100px);
                pointer-events: none;
            }
        }

        .form-check-label {
            // border: 2px solid $gray-d8d;
            background-color: $warning;
            padding: rem(50px) rem(24px) rem(24px) rem(24px);
            width: 100%;
            display: block;
            position: relative;
            text-align: center;
            min-height: rem(122px);
            color: $white;
        }

        .form-check-input {
            opacity: 0;
            position: absolute;
            z-index: -1;
        }
    }
}

.woocommerce-checkout-review-order-table input[type=radio].shipping_method {
    &+label {

        &::after {
            content: "";
            position: absolute;
            top: rem(10px);
            left: 50%;
            width: rem(24px);
            height: rem(24px);
            transform: translateX(-50%);
            border: 2px solid $white;
            ;
            border-radius: 50%;

        }

        &::before {
            content: "";
            position: absolute;
            top: rem(16px);
            left: 50%;
            width: rem(11px) !important;
            height: rem(11px) !important;
            transform: translateX(-50%) scale(0);
            border: 2px solid $white;
            border-radius: 50%;
            display: block !important;
            background-color: $info;
            transition-duration: .15s;
            margin: 0;
        }

        &~div {
            display: none;
            border: rem(3px) solid $warning;
            margin-top: rem(40px);
            padding: rem(16px) rem(16px);
            position: relative;

            p {
                font-size: rem(14px);
                color: $gray-6c6;

                a {
                    color: $warning;
                    text-decoration: underline;
                }
            }

            &::after {
                content: "\f078";
                font-family: 'Font Awesome 5 Free';
                font-weight: bold;
                position: absolute;
                font-size: rem(32px);
                top: rem(-47px);
                color: $warning;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }

    &:checked {
        &+label {
            &::before {
                background-color: $white;
                transform: translateX(-50%) scale(1);
            }

            &~div {
                display: block;
            }
        }
    }

}

.woocommerce-cart {
    .woocommerce {
        padding-bottom: rem(64px);
    }
}


.wpmc-steps-wrapper {

    margin-top: rem(52px);
    max-width: 992px;
    margin-left: auto;
    margin-right: auto;
    background-color: $gray-f6f;

    .woocommerce-billing-fields {
        // padding: 0 rem(15px);
    }

    .checkout {
        max-width: 982px;
        margin-left: auto;
        margin-right: auto;
        background-color: $white;
        // margin-top: rem(64px);
        width: 100%;

        &[name=checkout] {
            padding: rem(16px) rem(30px) rem(0px) rem(30px);
            margin-bottom: rem(32px);
        }

    }

    .form-row {
        flex: 0 0 50%;
        min-width: 50%;
        padding: 0 rem(15px);
        display: flex;
        align-items: flex-end;
        margin-bottom: rem(24px);
        margin-left: 0;
        margin-right: 0;

        @media #{$max-sm} {
            flex: 0 0 100%;
            min-width: 100%;
        }
    }

    .place-order {
        justify-content: flex-end;
    }

    h3 {
        color: $primary;
        font-size: rem(32px);
        margin-bottom: rem(32px);
    }

    #ship-to-different-address {
        padding: 0 rem(15px);
    }

    .login-body {
        padding: 0;
        padding-top: rem(120px);
        color: $gray-6c6;
    }

    .mo-openid-app-icons>p {
        color: $gray-6c6 !important;
    }

    .login-form-box {
        background-color: transparent;
        box-shadow: none;
        padding: 2rem;

        h2 {
            color: $primary;
        }
    }
}

.woocommerce-additional-fields {
    // padding: 0 rem(15px);
}

.woocommerce-additional-fields__field-wrapper,
.wpmc-steps-wrapper .woocommerce-shipping-fields__field-wrapper {
    margin: 0 rem(-15px);
}

.woocommerce-order {
    max-width: 992px;
    margin: 0 auto;
    margin-top: rem(24px);
    margin-bottom: rem(24px);

    .order-col-bg {
        background-color: $white;
    }
}

.woocommerce-form-coupon {
    display: flex;
    flex-wrap: wrap;

    p {
        &:nth-child(1) {
            flex: 0 0 100%;
        }
    }

    .button {}
}