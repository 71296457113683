.filter-homepage {
    margin-top: rem(-25px);
    margin-bottom: rem(25px);
    position: relative;
    z-index: 2
}

.bb_filter_form {
    background-color: $warning;
    border: none;
    border-radius: rem(8px);
    padding: rem(20px) rem(32px) rem(13px) rem(40px);
    max-width: rem(1226px);
    margin: 0 auto;

    @media #{$max-sm} {
        padding: rem(18px) rem(24px) rem(8px) rem(24px);
    }

    .form-group {
        @media #{$max-sm} {
            margin-bottom: rem(21px);
        }
    }

    label {
        color: $white;
        font-size: rem(16px);
        line-height: 1.187;
        margin-bottom: 0;
        position: relative;
    }

    .btn-primary {
        margin-bottom: 1rem;
        min-width: rem(169px);

        @media #{$max-sm} {
            width: 100%;
        }
    }



    .col-options {
        position: relative;
        padding-right: rem(32px);

        @media #{$max-md} {
            padding-right: rem(16px);
        }

        &::after {
            content: "";
            position: absolute;
            bottom: 1rem;
            right: rem(41px);
            width: rem(2px);
            height: rem(48px);
            background-color: rgba($white, 0.48);

            @media #{$max-md} {
                content: none;
            }
        }

        .col-o1 {
            @media #{$md} {
                padding-right: rem(32px);
            }
        }

        .col-o2 {
            @media #{$md} {
                padding-left: rem(4px);
                padding-right: rem(44px);
            }
        }

        .col-o3 {
            @media #{$md} {
                padding-left: rem(0px);
                padding-right: rem(53px);
            }
        }
    }

    .col-cta {
        padding-left: rem(0px);

        margin-left: rem(-10px);

        @media #{$max-md} {
            padding-left: rem(16px);
            margin-left: rem(0px);
        }

    }

    .icon-checkbox {
        min-width: rem(167px);
        margin-bottom: rem(16px);
        min-height: rem(42px);

        @media #{$max-md} {
            margin-bottom: rem(3px);
        }

        @media #{$max-sm} {
            width: 100%;
        }
    }


    .filter-nb {
        color: $white;
        margin-bottom: 1rem;
        font-size: rem(12px);
        margin-left: rem(23px);
        max-width: rem(167px);
        font-weight: 500;
        width: 100%;

        @media #{$max-sm} {
            margin-left: 0;
            line-height: 1.333;
            margin-bottom: rem(26px);
        }
    }



}

.tooltip {
    .arrow {}

    .tooltip-inner {
        background-color: $white;
        color: $black;
        font-size: rem(12px);
        padding: rem(7px) rem(18px);
        width: 100%;
        min-width: rem(200px);
        max-width: unset;
    }

    &.show {
        opacity: 1;
    }
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
    border-top-color: $white;
}

.filter-tooltip {
    position: relative;

    i {
        font-size: rem(12px);
        color: $white;
        transform: translateY(-1px);
        cursor: pointer;
    }

    &-body {
        position: absolute;
        width: rem(288px);
        height: rem(110px);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        top: 0;
        transform: translateY(-100%) translateX(-50%);
        left: 0;
        border-radius: 6px;
        border: 3px solid $white;
        opacity: 0;
        pointer-events: none;
        box-shadow: 0 0 5px 1px rgba($black, 0.19);
        transition-duration: .15s;

        &--largeur {
            background-image: url("../images/largeur.png");
        }

        &--hauteur {
            background-image: url("../images/hauteur.png");
        }

        &--diametre {
            background-image: url("../images/diametre.png");
        }
    }

    &:hover {
        .filter-tooltip-body {
            opacity: 1;
            pointer-events: all;
        }
    }
}


.twpss_shortcode {
    border: none
}