.s-marques {
    padding: rem(30px) 0;

    @media #{$max-xl} {
        padding: rem(20px) 0;
    }

    &-title {
        font-size: rem(38px);
        line-height: rem(47px);
        font-weight: bold;
        text-align: center;
        color: $primary;
        margin-bottom: rem(12px);

        @media #{$max-sm} {
            font-size: rem(28px);
            line-height: rem(32px);
        }
    }

    .container {
        max-width: rem(1241px);
    }

    &-cta {
        margin-top: rem(18px);

        @media #{$max-xl} {
            margin-top: rem(16px);
        }
    }
}