.woocommerce {
    #billing_country_field {
        display: none !important;
    }

    // padding: 32px 0 64px 0;

    .order-col-bg {
        background-color: rgba($gray-d8d, 0.14);
        padding: rem(32px);
        // font-size: rem(18px);
        // line-height: 1.555;
        // font-weight: 500;
        // margin-bottom: 32px;

        @media #{$max-sm} {
            padding: rem(16px);
        }

        p,
        address {
            font-size: rem(18px);
            line-height: 1.555;
            font-weight: 500;
            margin-bottom: rem(32px);

        }

        h2 {
            font-size: rem(24px);
            margin-bottom: rem(32px);
        }

        .shop_table td {
            padding-left: 0;
        }

        .woocommerce-table {
            tr {
                margin-bottom: rem(10px);
            }

            th,
            td {
                &:first-child {
                    flex: 0 0 60%;
                }

                &:last-child {
                    flex: 0 0 40%;
                    padding-left: rem(20px);
                }
            }
        }

        tfoot {
            display: flex;
            flex-direction: column;
            margin-top: rem(20px);
            padding-top: rem(16px);
        }

        ul {
            padding: 0;
            margin: 0;
            list-style: none;
            margin-bottom: rem(32px);

            li {
                padding-left: rem(32px);
                position: relative;
                font-weight: 600;
                margin-bottom: rem(22px);

                strong {
                    color: $blue-2;
                }

                @media #{$max-md} {
                    font-size: rem(14px);
                    margin-bottom: rem(18px);
                }

                @media #{$max-sm} {
                    font-size: rem(13px);
                }

                &:last-child {
                    margin-bottom: 0;
                }

                &::before {
                    content: "";
                    position: absolute;
                    left: rem(4px);
                    top: rem(4px);
                    height: rem(14px);
                    width: rem(14px);
                    border-radius: 100%;
                    background-color: $warning;
                }
            }
        }
    }
}



.woocommerce-order-received table tr {
    height: auto;
}

.woocommerce-order-received table tfoot tr:last-of-type {

    margin-top: 20px;
    padding-top: 16px;
}

.wc_payment_method {
    margin-bottom: 20px;
}

.woocommerce-view-order .woocommerce-MyAccount-content .woocommerce-table--order-details {
    margin-bottom: 0;

    thead {
        tr {
            align-items: center;


            &:first-child {
                .product-name {
                    font-weight: 400;
                }
            }
        }
    }
    

    tbody {
        border-bottom: 0;
    }

    tfoot {
        tr {
            &:last-of-type {
                border-top: 0;
            }

            &:last-of-type {
                margin-top: rem(32px);

                .woocommerce-Price-amount {
                    font-size: rem(24px);

                    @media #{$max-sm} {
                        font-size: rem(16px);
                    }
                }
            }
        }


    }

    .product-name {
        display: flex;
        align-items: center;
    }

    .product-quantity {
        color: $white;
        background-color: $warning;
        font-weight: 500;
        border-radius: rem(8px);
        padding: rem(1px) rem(9px);
        font-size: rem(14px);
        margin-left: rem(12px);
        display: flex;
        align-items: center;
    }

    .woocommerce-Price-amount {
        font-size: rem(18px);
        font-weight: 600;
        color: $blue-2;
    }

    .shippin-methode {
        color: $warning;
    }
}

.woocommerce-order-received table tfoot tr:last-of-type .woocommerce-Price-amount {
    color: $primary;
    font-size: rem(24px);
}