.breadcrumbs {
    font-size: rem(18px);
    line-height: 1.555;
    font-weight: 500;
    padding: rem(51px) 0 rem(31px) 0;

    @media #{$max-md} {
        padding: rem(32px) 0 rem(22px) 0;
    }

    @media #{$max-sm} {
        padding: rem(4px) 0 rem(16px) 0;
        font-size: rem(14px);
    }

    &>a {
        color: $gray-6c6;
    }

    &>i {
        color: $info;
        font-size: rem(14px);
        line-height: 1.285;
        margin: 0 rem(18px);

        @media #{$max-sm} {
            margin: 0 rem(12px);
            font-size: rem(10px);
        }
    }

    &>span {}
}